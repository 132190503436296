.application {
  height: 100%;
  width: 100%;
}

.application-text {
  font-family: Roboto;
  font-style: normal;
  background: #f7fcff;
  border-radius: 20px;
  padding-right: 0;
  padding-left: 0;
  p {
    font-weight: bold;
    font-size: 30px;
    line-height: 120%;
    color: #1a1f28;
  }
  ul.a {
    padding-left: 1rem;
    li {
      list-style: none;
      list-style-image: url("../../image/icons/list-style-circle.webp");
      span {
        font-weight: bold;
        font-size: 22px;
        line-height: 140%;
        text-align: justify;
        color: #444444;
        text-decoration: none;
      }
      a {
        font-style: normal;
        font-weight: normal;
        font-size: 22px;
        line-height: 150%;
        text-align: justify;
        color: #444444;
      }
    }
  }

  ul.b {
    li {
      list-style: none;
      list-style-image: url("../../image/icons/list-style-polygon.webp");
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 180%;
      text-align: justify;
      color: #444444;
    }
  }
}

@mixin objAbsoluteMiddle() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%); /* IE 9 */
  -webkit-transform: translate(-50%, -50%); /* Safari */
}

.middle {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 0;
  padding: 0;
  padding-bottom: 60%;
  img {
    @include objAbsoluteMiddle();
  }
}

.application-image {
  border-radius: 18px;
  position: relative;
  width: 100%;
  padding: 0;
  overflow: hidden;
  .card-image {
    float: left;
    object-fit: cover;
    img {
      border-radius: 18px;
      object-fit: cover;
      width: 100%;
    }
  }

  .slick-dots {
    bottom: 1vw;
    li {
      button {
        width: 16px;
        height: 16px;
        background: #c4c4c4;
        border-radius: 16px;
        &:before {
          position: absolute;
          width: 16px;
          height: 16px;
          border-radius: 16px;
          content: "";
          text-align: center;
          transition: all 0.3s ease-in-out;
        }
      }
      &.slick-active,
      &:hover {
        button {
          &:before {
            width: 16px;
            height: 16px;
            background: #ffffff;
            opacity: 1;
          }
        }
      }
    }
  }
}
