.container_box_about {
  display: flex;
  flex-direction: row;
  padding-top: 77px;
  padding-bottom: 50px;
  width: 100%;
  min-height: 490px;
  .box_left {
    background-image: url("../../image//Benefit_about/box_left.webp");
    background-size: 100% auto;
    width: 100%;
    height: auto;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;

    .logo_benefit {
      width: 93px;
      height: 52px;
      margin-right: 22px;
      margin-bottom: 12px;
      margin-top: 35px;
    }
    .box {
      padding-right: 30px;
      width: 100%;
      height: auto;
      position: relative;
      padding-left: 20px !important;
      span {
        font-weight: bold;
      }
      li {
        font-size: 16px;
        font-family: "Roboto";
        font-style: normal;
        line-height: 150%;
        display: flex;
        padding-bottom: 6px;
      }
    }
  }
  .box_right {
    transform: scalex(-1);
    .logo_benefit {
      transform: scalex(-1);
    }
    .box {
      transform: scalex(-1);
      padding-left: 50px !important;
    }
  }
  .icon_title_benefit {
    font-weight: unset !important;
    width: 20px;
    height: 20px;
    font-size: 10px !important;
    padding-top: 5px;
    background: linear-gradient(to right, #0000ff -3%, #66ccff 56%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
#about-us {
  .centerImage {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
