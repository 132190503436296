@charset "UTF-8";
/* đổ màu linear gradiant từ bottom lên top (vertical linear)/ truyền vào 2 mã màu. 
đây là đổ màu cho thẻ div và các thẻ container khác, không dùng được cho icon. 
icon sử dụng svg là đổ màu bằng svg path. 
*/
/* đổ màu linear gradiant từ bottom lên top (vertical linear)/ truyền vào 2 mã màu. 
đây là đổ màu cho thẻ div và các thẻ container khác, không dùng được cho icon. 
icon sử dụng svg là đổ màu bằng svg path. 
thay vì đổ linear background, hàm này đổ chi tiết hơn, chỉ đổ cho background-image.
*/
html,
body {
  color: #444;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  position: relative;
  height: auto;
  scroll-behavior: unset !important;
}
a {
  cursor: pointer;
}
.grecaptcha-badge {
  position: absolute !important;
  bottom: 90px !important;
  width: 70px !important;
  right: 0px !important;
  transition: width 0.8s !important;
}
.grecaptcha-badge:hover {
  width: 250px !important;
}

@media screen and (max-width: 767px) {
  .grecaptcha-badge {
    bottom: 90px !important;
    overflow: hidden !important;
  }
}

@media (min-width: 1400px) {
  .application .container {
    width: 1366px;
  }
}

.page-wrapper {
  min-height: calc(100vh - 60px);
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #888888 !important;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #888888 !important;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: #888888 !important;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: #888888 !important;
}

li {
  margin: 0;
  padding: 0;
}

*:hover {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

img {
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10+ and Edge */
  user-select: none;
  /* Standard syntax */
}

button:hover,
textarea:hover {
  border: none;
}

button:focus,
textarea:focus {
  outline: none !important;
  box-shadow: none !important;
}

.word-break {
  word-break: break-word;
}

.justify-content-between1 {
  justify-content: center !important;
  flex-wrap: wrap;
  .flex-item {
    margin-left: 35px;
    margin-right: 35px;
  }
}
input:focus,
select:focus,
select:active {
  outline: none !important;
  box-shadow: none !important;
}

a[href^="tel"] {
  color: inherit;
  text-decoration: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.btn_form {
  z-index: 1;
}
ul li {
  list-style: none;
}
.nav {
  user-select: none;
}
.nav > li > a:focus,
.nav > li > a:hover {
  background-color: initial;
}

#header {
  width: 100%;
  height: 650px;
  background-position: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
}

.navbar-brand {
  a {
    min-width: 63px;
    .second-logo {
      position: relative;
      top: -10px;
    }
    .first-logo {
      position: relative;
      top: -13px;
    }
  }
}
.flex-header {
  position: fixed;
  width: 100%;
  height: 95px;
  background: #fff;
  top: 0;
  left: 0;
  z-index: 10000;
  transition: background-color 0.2s ease-out;
  ul {
    li {
      a {
        color: #000 !important;
      }
    }
  }
  .image-active {
    display: block;
    position: relative;
    // top: -5px !important;
  }
}

.header-front-page.detail {
  background-position: top;
}
.header-front-page.detail .title-banner {
  font-style: normal;
  font-weight: bold;
  font-size: 44px;
  line-height: 54px;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
}
.header-front-page.detail .cls-banner-content {
  padding-top: 60px;
}
.header-front-page.detail .subtitle-banner {
  font-family: "Montserrat", sans-serif;
  margin-top: 0px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #ffffff;
}
.header-front-page.detail #header {
  height: 300px;
}

.flex-header .content-header {
  display: flex;
  padding-top: 20px;
  justify-content: space-between;
}

.flex-header.active {
  background: #fff;
  z-index: 2;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
  position: fixed;
  left: 0;
  width: 100%;
  padding-bottom: 15px;
  -webkit-transition: background-color ease-out 0.2s;
  -moz-transition: background-color ease-out 0.2s;
  transition: background-color ease-out 0.2s;
}
.flex-header.active .top_menu > ul > li > div > a,
.flex-header.active .top_menu > ul > li .dropdown-toggle {
  -webkit-transition: border ease-out 0.2s;
  -moz-transition: border ease-out 0.2s;
  transition: border ease-out 0.2s;
  color: #1a1f28;
  cursor: pointer;
}
.flex-header.active .top_menu > ul > li > div > a:hover,
.flex-header.active .top_menu > ul > li > div > a.active,
.flex-header.active .top_menu > ul > li .dropdown-toggle:hover,
.flex-header.active .top_menu > ul > li .dropdown-toggle.active {
  width: 100%;
  -webkit-transition: border ease-out 0.2s;
  -moz-transition: border ease-out 0.2s;
  transition: border ease-out 0.2s;
  border-image-source: linear-gradient(
    to right,
    #012f84,
    rgba(39, 157, 216, 0)
  );
}
.flex-header.active .content-header {
  margin: 0 auto;
  max-width: 1170px;
}
.flex-header.active .non-active {
  display: none;
}
.flex-header.active .image-active {
  display: block;
}
.flex-header.active .dropdown-icon > svg path {
  fill: #1a1f28;
}
.flex-header.active #hamburger span,
.flex-header.active #hamburger span:before,
.flex-header.active #hamburger span:after {
  background: #1a1f28;
}

.cls-banner-content {
  padding-top: 3.75rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.cls-banner-content.home {
  // height: calc(100% - 70px);
  padding-top: 220px;
}
.cls-banner-content .title-banner {
  font-size: 4.06255rem;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 800;
  line-height: 122px;
  color: #ffffff;
  text-align: center;
}
.cls-banner-content .and {
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
}
.cls-banner-content .subtitle-banner {
  font-family: "Montserrat", sans-serif;
  max-width: 580px;
  padding-top: 5px;
  font-weight: 400;
  font-size: 22px;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
}
.cls-banner-content .horizontal-line {
  margin-top: 30px;
  width: 128px;
  height: 2px;
  background: #fefeff;
  content: " ";
}
.cls-banner-content .intro-desciption-banner {
  padding-top: 35px;
  max-width: 705px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.67;
  letter-spacing: 0.2px;
  text-align: left;
  color: #feffff;
}

.header-front-page {
  // background-image: url("../../image/banner.webp");
  background-size: unset;
  background-position: center 0px;
  background-repeat: no-repeat;
}

.navbar-brand {
  display: flex;
  margin-top: 0;
  padding: 0;
  width: auto;
  height: 45px;
}

.top_menu > ul > li {
  position: relative;
  padding: 8px 15px;
}
.top_menu > ul > li a:not(.dropdown-item),
.top_menu > ul > li .dropdown-toggle {
  font-family: Roboto;
  font-size: 22px;
  font-weight: 500;
  line-height: 25px;
  color: #fff;
  text-decoration: none;
  padding: 0;
  padding-bottom: 10px;
  border-bottom: 3px solid transparent;
  -webkit-transition: border ease-out 0.2s;
  -moz-transition: border ease-out 0.2s;
  transition: border ease-out 0.2s;
}
.top_menu > ul > li a:not(.dropdown-item).active,
.top_menu > ul > li .dropdown-toggle.active {
  font-weight: bold;
}
.top_menu > ul > li:last-child {
  padding-right: 0px;
}
.top_menu > ul > li .sub-menu {
  position: absolute;
  top: 25px;
  width: 375px;
  background-color: #fff;
  border-radius: 16px;
  padding-left: 0;
  z-index: 999;
  left: -999rem;
  opacity: 0;
  height: 0;
  visibility: hidden;
  overflow: hidden;
  -webkit-transform: translateY(30px);
  -moz-transform: translateY(30px);
  -ms-transform: translateY(30px);
  -o-transform: translateY(30px);
  transform: translateY(30px);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
  -webkit-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transition-property: opacity, visibility, -webkit-transform;
  -o-transition-property: transform, opacity, visibility;
  transition-property: transform, opacity, visibility;
  transition-property: transform, opacity, visibility, -webkit-transform;
}
.top_menu > ul > li .sub-menu > li {
  padding: 12px 25px;
}
.top_menu > ul > li .sub-menu > li:hover {
  background: #eef7fc;
}
.top_menu > ul > li .sub-menu > li > a {
  color: #1a1f28;
  width: 100%;
}
.top_menu > ul > li .sub-menu > li:not(:last-child) {
  border-bottom: 1px solid #f2f2f2;
}
.top_menu > ul > li .arrowdown-mobile {
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.flex {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.title-block {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 140%;
  color: #154499;
  text-align: center;
  text-transform: uppercase;
}
.title-block.first-section {
  margin-bottom: 1.5%;
}

.innovation-ecosystem {
  padding-top: 4.3755rem;
  padding-bottom: 5rem;
  overflow: hidden;
}
.innovation-ecosystem .block-content {
  padding-bottom: 34px;
}
.innovation-ecosystem .block-content .content > div {
  line-height: 1.6;
}
.innovation-ecosystem .block-content .content > div:not(.last-child) {
  margin-bottom: 22px;
}
.innovation-ecosystem .corporate .title-block {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 140%;
  text-align: center;
  color: #1a1f28;
}
.innovation-ecosystem .corporate .short-description {
  font-style: normal;
  font-weight: normal;
  line-height: 160%;
  text-align: center;
  max-width: 620px;
  margin-top: 13px;
  margin-bottom: 2.1875rem;
}
.innovation-ecosystem .corporate .item {
  display: flex;
  flex-direction: column;
  margin: 10px;
  padding: 2px;
  flex: 1;
  border-radius: 20px;
  background: linear-gradient(88.46deg, #003087 -6.62%, #279dd8 109.01%);
  overflow: hidden;
}
.innovation-ecosystem .corporate .item .content-item {
  padding: 20px;
  min-height: 135px;
}
.innovation-ecosystem .corporate .item .content-item .pl5 {
  padding-left: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  color: #fff;
}
.innovation-ecosystem .corporate .item .content-below {
  min-height: 362px;
  border-radius: 20px;
  background: #fff;
  padding: 25px;
  flex: 1;
}
.innovation-ecosystem .corporate .item .content-below .title-item {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 30px;
  text-transform: uppercase;
  color: #ffffff;
}
.innovation-ecosystem .corporate .item .content-below ul {
  margin-bottom: 0;
  padding: 0;
}
.innovation-ecosystem .corporate .item .content-below ul li {
  font-style: normal;
  font-weight: normal;
  line-height: 1.4;
  list-style: none;
  background-image: url("../../image/li-icon.webp");
  background-repeat: no-repeat;
  background-position: left 5px;
  padding-left: 23px;
}
.innovation-ecosystem .corporate .item .content-below ul li:not(:last-child) {
  margin-bottom: 15px;
}
.innovation-ecosystem .corporate .item .content-below .text-des {
  font-style: italic;
}
.innovation-ecosystem .corporate .item .content-below .contact {
  color: #003087;
  font-style: normal;
  font-weight: normal;
  text-decoration-line: underline;
  line-height: 1.4;
  padding-left: 23px;
  display: block;
  margin-top: 15px;
}

.innovation-as-service {
  background-color: #eef7fc;
  padding-bottom: 2.8125rem;
  padding-top: 4.375rem;
}
.innovation-as-service .innovation-panership-content {
  margin-bottom: 2.125rem;
}
.innovation-as-service .block {
  padding: 12px;
  overflow: hidden;
}
.innovation-as-service .block .inside {
  background: #fff;
  border-radius: 20px;
  margin-bottom: 24px;
  box-shadow: 2px 10px 25px rgba(0, 0, 0, 0.05);
}
@media (max-width: 767px) {
  .innovation-as-service .block .inside {
    margin-bottom: 20px;
  }
}
.innovation-as-service .block .inside.gradient {
  background: linear-gradient(86.26deg, #003087 -6.62%, #279dd8 109.01%);
  margin-bottom: 0;
}
.innovation-as-service .block .inside.gradient .content {
  background: #fff;
  border-radius: 19px;
}
.innovation-as-service .block .inside.gradient .content-below {
  padding: 18px 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.innovation-as-service .block .inside .title-block {
  height: 76px;
  position: relative;
}
.innovation-as-service .block .inside .title-block .cls-title {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 1.4;
  text-transform: capitalize;
  color: #1a1f28;
  position: absolute;
  top: 42px;
  left: 114px;
  text-align: left;
}
.innovation-as-service .block .inside .title-block .image {
  position: absolute;
  left: 17px;
  top: 24px;
}
.innovation-as-service .block .inside .pl5 {
  padding-left: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 180%;
  color: #1a1f28;
}
.innovation-as-service .block .content {
  margin-top: 3.4375rem;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 40px;
  font-style: normal;
  font-weight: normal;
  line-height: 25px;
}
.innovation-as-service .block .content.custom-line-height {
  line-height: 180%;
}
.innovation-as-service .block .content .title {
  margin-top: 10px;
  margin-bottom: 6px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.4;
  color: #1a1f28;
}
.innovation-as-service .block .read-more > a {
  display: block;
  font-style: normal;
  font-weight: normal;
  line-height: 19px;
  color: #003087;
  cursor: pointer;
  text-decoration: underline;
  margin-top: 12px;
}

.padding-block-1 {
  margin-top: 40px;
  padding-left: 50px;
}

.border-bottom-block {
  border-bottom: 1px dashed #c6c6c6;
}

.pat50 {
  padding-top: 40px;
}

.par20 {
  padding-right: 20px;
}

.height65 {
  height: 65px;
}

@media only screen and (min-width: 768px) {
  .top_menu > ul > li > div > a:hover,
  .top_menu > ul > li > div > a.active {
    -webkit-transition: border ease-out 0.2s;
    -moz-transition: border ease-out 0.2s;
    transition: border ease-out 0.2s;
    border-image-source: linear-gradient(
      to right,
      #1a1f28,
      rgba(39, 157, 216, 0)
    );
    border-image-slice: 1;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 3px solid !important;
  }
  .top_menu > ul > li .sub-menu > li > a {
    display: block;
    padding-bottom: 0;
  }
  .navbar-nav {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row !important;
  }
  .navbar-nav .contact-us .text-nowrap-md {
    white-space: nowrap;
  }
}

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1200px;
  }
}

@media only screen and (max-width: 1199px) {
  // header .container {
  //   max-width: 100%;
  // }
  .top_menu > ul > li {
    padding: 8px;
  }
  .application .content {
    max-height: 700px;
  }
}

@media only screen and (max-width: 991px) {
  .top_menu.collapse {
    display: block !important;
  }
}

.menu_mobile {
  display: none !important;
}

@media only screen and (max-width: 767px) {
  .top_menu.collapse {
    display: none !important;
  }
  .menu_mobile {
    display: block !important;
  }
  .burger {
    display: block;
    padding: 6px 4px;
    height: 32px;
    width: 32px;
    position: absolute;
    top: 30px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    cursor: pointer;
  }
  .line {
    display: block;
    // height: 2.5px;
    border: 1.5px solid #000;
    width: 100%;
    border-radius: 10px;
    background: #000;
    transition: all 0.5s ease;
  }
  .line2 {
    width: 100%;
  }
  .line3 {
    width: 100%;
  }
  .nav_show {
    transform: translateX(-100%);
  }
  .line1_transform {
    transform: rotateZ(45deg) translateY(150%) translateX(35%);
    width: 120%;
  }
  .line2_transform {
    opacity: 0;
  }
  .line3_transform {
    transform: rotateZ(-45deg) translateY(-150%) translateX(29%);
    width: 120%;
  }
  .line_transform {
    color: #ffffff;
    border: 1px solid #ffffff;
    background: #fff;
  }

  //menu
  .nav_show {
    transform: translateX(-100%);
  }
  // .nav_mobile {
  //   display: flex;
  //   flex-direction: column;
  //   align-items: flex-start;
  //   justify-content: flex-start;
  //   padding: 48px 0px 0px 0px;
  //   gap: 40px;
  // }
  .nav_mobile {
    display: flex;
    justify-content: flex-start;
    padding: 48px 0px 0px 0px;
    gap: 40px;
    width: 66.6666%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 100%;
    flex-direction: column;
    align-items: center;
    transition: transform 0.6s ease;
    background: linear-gradient(45deg, #4b71f4 20%, #5b79fd 50%, #5973ed 80%);
  }
  .new-tag-a {
    display: block;
    width: 100%;
    padding-top: 18px;
    padding-bottom: 9px;
    padding-left: 24px;
    margin-bottom: 12px;
    margin-top: 12px;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    text-transform: capitalize;
    color: #ffff !important;
    cursor: pointer;
  }
  .flex-header ul li a {
    color: #ffff !important;
  }
  .nav ul {
    width: 100%;
    gap: 0;
    flex-direction: column;
    text-align: left;
    padding-top: 45px;
    position: relative;
    padding-left: 0px;
    li::after {
      content: " ";
      width: 100%;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      position: absolute;
      margin-left: 0px;
      margin-right: 24px !important;
    }
    li:last-child::after {
      display: none;
    }
  }
  .containerDetail_mobile {
    max-width: 100% !important;
    padding-right: 45px !important;
  }
  .flex-header {
    &.fixed {
      .line {
        border: 1.5px solid #000;
        background: #000;
      }
    }
  }
}

.dropdown-menu {
  padding: 0;
  min-width: 4rem;
  overflow: hidden;
  border-width: 0;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
}
.dropdown-menu .dropdown-item:active,
.dropdown-menu .dropdown-item:focus {
  color: initial;
  background-color: #fff;
  border: none;
}
.dropdown-menu .dropdown-item:hover,
.dropdown-menu .dropdown-item:focus {
  color: initial;
  background-color: #eef7fc;
}

.home .launching-button {
  color: #003087;
  background: white;
  font-weight: 500;
  border: none;
  padding: 0 12px;
  display: block;
  max-width: 220px;
  min-height: 45px;
  line-height: 45px;
}
.home .launching-button:hover {
  background: linear-gradient(82.56deg, #279dd8 -6.62%, #003087 109.01%);
  color: #fff;
}
.home .launching-button:focus {
  box-shadow: none;
  border: none;
  outline: none;
}

.home .intro-content {
  color: white;
  font-size: 40px;
  line-height: 1.3;
  margin-bottom: 30px;
}

@media only screen and (min-width: 992px) {
  .home .banner-layer {
    // padding: 20px;
    // max-width: 600px;
    // flex-basis: 650px;
    // background: url("../../image//video-decor.webp") no-repeat;
    background-size: contain;
  }
  .home .banner-layer .video-wrapper {
    border-radius: 12px;
    overflow: hidden;
    height: 278px;
    width: 484px;
  }
  .top_menu.collapse {
    display: block !important;
  }
  .navbar-toggler {
    display: none;
  }
  .top_menu > ul > li:hover .sub-menu {
    height: auto;
    left: 0;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    visibility: visible;
    opacity: 1;
    margin-top: 15px;
  }
}

@media only screen and (max-width: 768px) {
  html {
    font-size: 14px;
  }
  .home .intro-content {
    font-size: 30px;
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 768px) and (max-width: 576px) {
  .home .intro-content {
    font-size: 22px;
  }
}

@media only screen and (max-width: 768px) {
  .home .intro-content .fs24 {
    font-size: 1.25rem;
  }
  .home .banner-layer {
    padding: 15px;
  }
  .home .launching-button {
    margin: 0 auto;
  }
  .navbar-nav .dropdown-menu {
    position: relative !important;
    transform: none !important;
    width: 100px;
  }
  .container {
    padding-left: 15px;
    padding-right: 15px;
  }
  .top_menu > ul > li .dropdown-toggle {
    color: #1a1f28;
  }
  .flex-header {
    padding-top: 26px;
  }
  .flex-header.active {
    padding: 20px 20px 15px;
  }
  .flex-header.active .navbar-brand {
    height: 29px;
  }
  .flex-header.active .top_menu > ul > li > div > a.active,
  .flex-header.active .top_menu > ul > li > div > a:hover,
  .flex-header.active .top_menu > ul > li > div > a:focus {
    border-image-source: none !important;
  }
  .flex-header .content-header {
    padding-top: 0;
  }
  .title-block {
    line-height: 44px;
    max-width: 100%;
  }
  .innovation-panership-content {
    margin-bottom: 22px;
  }
  .innovation-as-service .block {
    padding: 0 15px;
  }
  .innovation-as-service .block .content {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    margin-top: 25px;
  }
  .innovation-as-service .block .title-block .cls-title {
    left: 110px;
    text-align: left;
  }
  .innovation-as-service .block .inside.gradient .content-below {
    padding: 14px 0;
  }
  .header-front-page.detail .title-banner {
    font-size: 36px;
    margin-bottom: 8px;
  }
  .innovation-ecosystem {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .innovation-ecosystem .corporate .short-description {
    margin-top: 7px;
    margin-bottom: -7px;
  }
  .innovation-as-service,
  .contact-us {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .padding-block-1 {
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
  }
  .cls-banner-content {
    padding-top: 40px;
  }
  .cls-banner-content.home {
    height: auto;
    padding-top: 15px;
  }
  .cls-banner-content.home iframe {
    height: 300px;
  }
  .cls-banner-content .title-banner {
    font-size: 40px;
    line-height: initial !important;
    margin-bottom: 10px;
  }
  .cls-banner-content .subtitle-banner {
    font-size: 14px;
    line-height: 150%;
  }
  .benefits-advantages .content .image {
    max-width: 70px;
  }
  .application .content {
    height: auto;
  }
}

.bold,
strong {
  font-weight: 700;
}

.fs18 {
  font-size: 1.125rem;
}

.fs20 {
  font-size: 1.25rem;
}

.fs22 {
  font-size: 1.375rem;
}

.fs24 {
  font-size: 1.5rem;
}

footer {
  font-size: 14px;
  color: #31486c;
  background-color: #eef7fc;
  min-height: 60px;
}

.download-file {
  text-decoration: underline;
}
.download-file:hover {
  text-decoration: none;
}

.our-approach-block .image-mobile {
  display: none;
}

@media (max-width: 767px) {
  .flex-header {
    height: 80px;
    overflow: hidden !important;
    padding-top: 20px;
    .image-active {
      height: 80px;
      img {
        height: 45px;
      }
    }
  }
}

@media (max-width: 576px) {
  .our-approach-block .image-mobile {
    display: block;
  }
  .our-approach-block .image {
    display: none;
  }
}

.trainning .item {
  display: flex;
  flex-direction: column;
  margin: 10px;
  padding: 2px;
  flex: 1;
  overflow: hidden;
  background: linear-gradient(87.87deg, #003087 -6.62%, #279dd8 109.01%);
  border-radius: 20px;
}
@media (max-width: 767px) {
  .trainning .item {
    flex: unset;
    width: 100%;
  }
}
.trainning .item .content-item {
  padding: 20px;
  min-height: 110px;
}
.trainning .item .content-item .pl5 {
  padding-left: 15px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
  color: #ffffff;
}
.trainning .item .content-below {
  flex: 1;
  background: white;
  border-radius: 15px;
  padding: 25px;
  font-style: normal;
  font-weight: normal;
  line-height: 240%;
  color: #1a1f28;
}
.trainning .item .content-below .title-item {
  display: none;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 30px;
  text-transform: uppercase;
  color: #ffffff;
}
.trainning .item .content-below ul {
  margin-bottom: 0;
  padding: 0;
}
.trainning .item .content-below ul:first-child {
  padding-right: 40px;
}
.trainning .item .content-below ul li {
  list-style: none;
  background-image: url("../../image/li-color.webp");
  background-repeat: no-repeat;
  background-position: left 15px;
  padding-left: 15px;
}
@media (max-width: 576px) {
  .trainning .item .content-below {
    min-height: auto;
  }
}

.cls-border {
  border: 1px dashed black;
  width: 100%;
  opacity: 0.1;
}

.mbt40 {
  margin-bottom: 40px;
}

.co-creation .services-grid {
  margin-top: 40px;
}

.co-creation .item {
  min-width: 374px;
  display: flex;
  flex: 1;
  align-items: center;
  padding: 0 10px;
  display: flex;
}
.co-creation .item .content {
  box-shadow: 2px 10px 25px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  margin-bottom: 24px;
  background: white;
  display: flex;
  flex: 1;
  padding: 18px 25px;
  align-items: center;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 120%;
  color: #1a1f28;
}
.co-creation .item .image {
  margin-right: 10px;
  min-width: 45px;
}

@media (min-width: 576px) {
  #popup-show-noti .modal-dialog {
    max-width: 700px;
    margin: 1.75rem auto;
  }
  #popup-show-noti .modal-dialog .title-block {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
  }
  #popup-show-noti .modal-dialog,
  #popup-show-noti .modal-content,
  #popup-show-noti .modal-body {
    min-height: 425px;
  }
  #popup-show-noti .modal-body {
    background: url("../../image/popup-decor.webp") center 14% no-repeat;
  }
}

#loading {
  background: radial-gradient(
    91.93% 91.93% at 50% 45.7%,
    #003087 0%,
    #002a75 100%
  );
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1000;
  margin-top: 0px;
  top: 0px;
}

#loading-center {
  width: 100%;
  height: 100%;
  position: relative;
}

#loading-center-absolute {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 60px;
  width: 60px;
  margin-top: -30px;
  margin-left: -30px;
  -webkit-animation: loading-center-absolute 1s infinite;
  animation: loading-center-absolute 1s infinite;
}

.object {
  width: 20px;
  height: 20px;
  background-color: #fff;
  float: left;
  -moz-border-radius: 50% 50% 50% 50%;
  -webkit-border-radius: 50% 50% 50% 50%;
  border-radius: 50% 50% 50% 50%;
  margin-right: 20px;
  margin-bottom: 20px;
}

.object:nth-child(2n + 0) {
  margin-right: 0px;
}

#object_one {
  -webkit-animation: object_one 1s infinite;
  animation: object_one 1s infinite;
}

#object_two {
  -webkit-animation: object_two 1s infinite;
  animation: object_two 1s infinite;
}

#object_three {
  -webkit-animation: object_three 1s infinite;
  animation: object_three 1s infinite;
}

#object_four {
  -webkit-animation: object_four 1s infinite;
  animation: object_four 1s infinite;
}

@-webkit-keyframes loading-center-absolute {
  100% {
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loading-center-absolute {
  100% {
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes object_one {
  50% {
    -ms-transform: translate(20px, 20px);
    -webkit-transform: translate(20px, 20px);
    transform: translate(20px, 20px);
  }
}

@keyframes object_one {
  50% {
    -ms-transform: translate(20px, 20px);
    -webkit-transform: translate(20px, 20px);
    transform: translate(20px, 20px);
  }
}

@-webkit-keyframes object_two {
  50% {
    -ms-transform: translate(-20px, 20px);
    -webkit-transform: translate(-20px, 20px);
    transform: translate(-20px, 20px);
  }
}

@keyframes object_two {
  50% {
    -ms-transform: translate(-20px, 20px);
    -webkit-transform: translate(-20px, 20px);
    transform: translate(-20px, 20px);
  }
}

@-webkit-keyframes object_three {
  50% {
    -ms-transform: translate(20px, -20px);
    -webkit-transform: translate(20px, -20px);
    transform: translate(20px, -20px);
  }
}

@keyframes object_three {
  50% {
    -ms-transform: translate(20px, -20px);
    -webkit-transform: translate(20px, -20px);
    transform: translate(20px, -20px);
  }
}

@-webkit-keyframes object_four {
  50% {
    -ms-transform: translate(-20px, -20px);
    -webkit-transform: translate(-20px, -20px);
    transform: translate(-20px, -20px);
  }
}

@keyframes object_four {
  50% {
    -ms-transform: translate(-20px, -20px);
    -webkit-transform: translate(-20px, -20px);
    transform: translate(-20px, -20px);
  }
}

.btn-gradient {
  position: relative;
  overflow: hidden;
  z-index: 1;
  background: linear-gradient(82.56deg, #003087 -6.62%, #279dd8 109.01%);
  border-radius: 4px;
  height: 48px;
  width: 175px;
}
@media only screen and (max-width: 768px) {
  .btn-gradient {
    width: 100%;
  }
}
.btn-gradient::after {
  position: absolute;
  background: #fff;
  content: "";
  height: 155px;
  left: -75px;
  opacity: 0.2;
  top: -50px;
  -webkit-transform: rotate(35deg);
  transform: rotate(35deg);
  -webkit-transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
  transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
  width: 50px;
}
.btn-gradient:hover::after {
  left: 120%;
  -webkit-transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
  transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
}

.button-tags {
  position: relative;
  background-image: linear-gradient(to right, #02ecff 50%, #013084 100%);
  border-radius: 100px;
  box-sizing: border-box;
  display: inline-block;
  height: 50px;
  padding: 1px;
  margin-bottom: 15px;
  margin-right: 10px;
}
@media (max-width: 1199px) {
  .button-tags.oneline-btn {
    top: 7px;
  }
}
.button-tags .button-bg {
  background: radial-gradient(
    91.93% 91.93% at 50% 45.7%,
    #003087 0%,
    #002a75 100%
  );
  display: inline-block;
  text-align: center;
  border-radius: 100px;
  height: 48px;
  transition: background 0.5s ease;
  width: 100%;
}
.button-tags .button-bg > div {
  display: flex;
  align-items: center;
  padding-left: 25px;
  padding-right: 25px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 100px;
  height: 48px;
  line-height: 1.02;
  min-width: 6.25rem;
  transition: background 0.5s ease;
}
.button-tags .button-bg > div > strong {
  font-size: 18px;
}
.button-tags .button-bg > div span {
  display: block;
  font-weight: 300;
  font-size: 13px;
  line-height: 1.2;
}
.button-tags .button-bg > div:hover {
  background: transparent;
}

#hamburger {
  position: relative;
}

#hamburger {
  cursor: pointer;
  padding: 10px 35px 16px 0px;
}

#hamburger span,
#hamburger span:before,
#hamburger span:after {
  cursor: pointer;
  border-radius: 1px;
  height: 3px;
  width: 35px;
  background: #fff;
  position: absolute;
  display: inline-block;
  content: "";
  left: 0;
}

#hamburger span:before {
  top: -10px;
}

#hamburger span:after {
  bottom: -10px;
}

#hamburger span,
#hamburger span:before,
#hamburger span:after {
  -webkit-transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
  -moz-transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
  /* easeInOutCubic */
}

#hamburger[aria-expanded="true"] span {
  background-color: transparent !important;
}
#hamburger[aria-expanded="true"] span::before,
#hamburger[aria-expanded="true"] span::after {
  top: 0;
}
#hamburger[aria-expanded="true"] span::before {
  transform: rotate(45deg);
}
#hamburger[aria-expanded="true"] span::after {
  transform: translateY(-10px) rotate(-45deg);
  top: 10px;
}

.contact-us {
  padding-top: 5.3125rem;
  padding-bottom: 5.3125rem;
}
.contact-us input,
.contact-us textarea {
  color: #1a1f28;
  height: 56px;
  padding-left: 0;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #ccc;
}
.contact-us input:focus,
.contact-us textarea:focus {
  overflow: hidden;
  padding-bottom: calc(0.375rem - 1px);
  border-top: none !important;
  border-bottom: 2px solid transparent;
  border-image: url("../../image/input-focus-decor.webp") 30 stretch;
}
.contact-us textarea {
  padding-top: 13px;
  resize: none;
}
.contact-us button[type="submit"] {
  margin-top: 2.125rem;
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}
.contact-us button[type="submit"]:active,
.contact-us button[type="submit"]:focus {
  border: none !important;
}
.contact-us #form-error {
  display: none;
}
.contact-us #g-recaptcha-error {
  color: red;
  margin-top: 10px;
}
.contact-us .contact-wrapper {
  position: relative;
  background: #eef7fc;
  border-radius: 30px;
  width: 100%;
  height: 313px;
}

.contact-border .company-name {
  font-family: Roboto;
  font-weight: bold;
  font-size: 24px;
  line-height: 140%;
}

.contact-us .contact-wrapper .contact-border {
  display: flex;
  flex-flow: column;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: -12px;
  left: 12px;
  border: 2px solid #003087;
  border-radius: 30px;
  padding: 20px;
}
.contact-us .contact-wrapper .contact-border ul {
  padding-left: 0;
  max-width: 400px;
  margin: 0 auto;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
}
.contact-us .contact-wrapper .contact-border ul > li > img {
  margin-right: 12px;
  min-width: 50px;
  width: 50px;
}
.contact-us .contact-wrapper .contact-border ul > li:not(:last-child) {
  margin-bottom: 32px;
}
@media only screen and (max-width: 768px) {
  .contact-us {
    padding-top: 40px;
    padding-bottom: 40px;
    background: none;
  }
  .contact-us .banner > img {
    width: 56%;
  }
  .contact-us .contact-wrapper {
    margin: 0 15px 0 0;
  }
  .contact-us .contact-wrapper .contact-border {
    padding: 0 15px;
  }
}

.page-detail .title {
  font-weight: 600;
  color: #1a1f28;
}

.page-detail .section-des {
  margin-bottom: 3rem;
}
@media (max-width: 767px) {
  .page-detail .section-des {
    margin-bottom: 2rem;
  }
}

.inspire-insight {
  width: 100%;
}
.inspire-insight .title-block {
  font-size: 36px;
}
.inspire-insight .first-section {
  padding-top: 3.75rem;
  padding-bottom: 3.75rem;
}
.inspire-insight .first-section .description {
  max-width: 480px;
  margin: 0 auto;
  text-align: center;
}
.inspire-insight .we-focus-on {
  padding-top: 3.75rem;
  padding-bottom: 5rem;
}
@media (max-width: 767px) {
  .inspire-insight .we-focus-on {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
}
.inspire-insight .we-focus-on > .row:nth-child(even) > div:nth-child(odd),
.inspire-insight .we-focus-on > .row:nth-child(odd) > div:nth-child(even) {
  background-color: #eef7fc;
  border-radius: 16px;
}
.inspire-insight .we-focus-on .box {
  min-height: 208px;
}
@media (max-width: 767px) {
  .inspire-insight .we-focus-on .box {
    min-height: 158px;
  }
}
.inspire-insight .we-focus-on .box > .icon {
  height: 88px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 991px) {
  .inspire-insight .we-focus-on > .row > div:nth-child(1),
  .inspire-insight .we-focus-on > .row > div:nth-child(4) {
    background-color: #fff !important;
  }
  .inspire-insight .we-focus-on > .row > div:nth-child(2),
  .inspire-insight .we-focus-on > .row > div:nth-child(3) {
    background-color: #eef7fc;
    border-radius: 16px;
  }
}
.inspire-insight .delivery {
  background: url("../../image/delivery-layer-decor.webp") center center/cover
    no-repeat;
  min-height: 585px;
  padding-top: 3rem;
  padding-bottom: 3rem;
  display: flex;
  align-items: center;
}

.acceleration-idea {
  padding-top: 3.75rem;
  padding-bottom: 3.75rem;
  min-height: calc(100vh - 360px);
  width: 100%;
}
.acceleration-idea.co-creation {
  border-bottom: 1px solid #d4e0e7;
}
.acceleration-idea .container > .description {
  max-width: 930px;
  margin: 0 auto 2.5rem;
}
.acceleration-idea .gradient-box {
  background: linear-gradient(89.2deg, #003087 -6.62%, #279dd8 109.01%);
  padding: 2px;
  border-radius: 18px;
  min-height: 610px;
  margin-bottom: 1.5rem;
  display: flex;
  flex-flow: column;
  -ms-flex-flow: column;
  justify-content: space-between;
}
.acceleration-idea .gradient-box.vn {
  min-height: 695px;
}
@media (max-width: 767px) {
  .acceleration-idea .gradient-box {
    min-height: 610px;
  }
}
.acceleration-idea .box-header {
  padding: 17px 25px;
  max-height: 70px;
}
.acceleration-idea .box-header > div {
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 600;
}
.acceleration-idea .box-header > img {
  margin-right: 12px;
}
.acceleration-idea .acceleration-content {
  position: relative;
  padding: 1.5rem 1.5625rem 0;
}
.acceleration-idea .acceleration-content .light-blue-block {
  padding: 20px;
  background: #eef7fc;
  border-radius: 10px;
  height: 100%;
}
.acceleration-idea .acceleration-content .light-blue-block > .description {
  font-size: 14px;
  margin-top: 10px;
}
@media (min-width: 768px) {
  .acceleration-idea
    .acceleration-content
    .row
    > div:first-child
    .light-blue-block {
    padding-right: 3rem;
  }
  .acceleration-idea
    .acceleration-content
    .row
    > div:last-child
    .light-blue-block {
    padding-left: 3rem;
  }
  .acceleration-idea .acceleration-content .light-blue-block {
    padding: 30px 20px;
    min-height: 230px;
    display: flex;
    align-items: center;
  }
}
.acceleration-idea .acceleration-content > .accelerator-decor {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30%;
}
.acceleration-idea .box-content {
  background: #fff;
  border-radius: 17px;
  min-height: 535px;
}
.acceleration-idea .box-content.vn,
.acceleration-idea .box-content.acceleration.vn {
  min-height: 621px;
}
.acceleration-idea .box-content.acceleration {
  min-height: 535px;
  height: initial;
  display: flex;
  align-items: center;
}
@media (max-width: 767px) {
  .acceleration-idea .box-content {
    min-height: 540px;
    height: 540px;
  }
}
.acceleration-idea .box-content.vn .block {
  min-height: 310px;
}
.acceleration-idea .box-content .block {
  height: calc(50% - 1px);
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 20px 25px 30px;
}
.acceleration-idea .box-content .block > .description {
  font-size: 15px;
}
.acceleration-idea .box-content .block > img {
  width: 80px;
}

.launching-event header .content {
  margin-bottom: -10%;
  border-radius: 10px;
  overflow: hidden;
  max-height: 550px;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.launching-event .content {
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
}
.launching-event .content p {
  text-align: justify;
}

.launching-event .page-content {
  padding-top: 7.5%;
  padding-bottom: 7.2%;
}
.launching-event .page-content .title {
  font-weight: bold;
  font-size: 34px;
  line-height: 1.4;
  color: #1a1f28;
}
.launching-event .page-content > .content > p {
  margin-bottom: 28px;
}

.launching-event .see-more > h6 {
  font-size: 1.125rem;
  font-weight: 600;
}

.launching-event .see-more div > a {
  color: #003087;
  font-weight: bold;
}

@media (max-width: 767px) {
  .launching-event #header {
    height: 350px;
  }
  .launching-event .page-content .title {
    font-size: 25px;
  }
}

.galary-image {
  width: 100%;
  height: 380px;
}

.camera-text {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 21.8px;
  line-height: 150%;
  /* or 33px */

  display: flex;
  align-items: center;
  text-align: justify;

  color: #ffffff;
}

#camera-title {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 49px;
  color: #ffffff;
  padding-bottom: 9px;
}

.section {
  padding-top: 4.3755rem;
  overflow: hidden;
}
// @media screen and (max-width: 991px) {
//   .section {
//     padding-top: 2rem;
//   }
// }
// @media screen and (max-width: 767px) {
//   .section {
//     padding-top: 1.5rem;
//   }
// }

.solution {
  p {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 140%;
    align-items: center;
    text-align: center;

    color: #ffffff;
  }
}

.solution-button {
  background: linear-gradient(87.95deg, #003087 -6.62%, #279dd8 109.01%);
  border-radius: 18px;
  height: 76px;
  width: 100%;
  display: flex;
  align-items: center;
  p {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 140%;
    text-align: center;
    color: #ffffff;
  }
}

.about {
  background: linear-gradient(87.95deg, #003087 -6.62%, #279dd8 109.01%);
  font-family: Roboto;
  font-style: normal;
  color: #ffffff;
  text-align: justify;
  img {
    border-radius: 18px;
    min-height: 311px;
    width: 100%;
    align-self: center;
  }
}

#about-title {
  font-weight: bold;
  font-size: 22px;
  line-height: 200%;
}

#about-text {
  font-weight: normal;
  font-size: 18px;
  line-height: 200%;
}

.button-solution {
  width: 280px;
  min-height: 54px;
  background: linear-gradient(87.95deg, #003087 -6.62%, #279dd8 109.01%);
  border-radius: 12px;
  border: unset;
  p {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 140%;
    color: #ffffff;
  }
}

.border-camera {
  width: 340px;
  height: 279px;
  border: 1.5px solid #279dd8;
  box-sizing: border-box;
  border-radius: 20px;
}

.box-solution {
  background: #eef7fc;
  height: 292px;
  border-radius: 12px;
  width: 326px;
  p {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 140%;
    text-align: center;
    color: #373737;
    padding-right: 23px !important;
    padding-left: 23px !important;
  }
}

.full-width {
  width: 100%;
}

.button-tech {
  width: 207px;
  height: 78px;
  background: linear-gradient(87.95deg, #003087 -6.62%, #279dd8 109.01%);
  box-shadow: 2px 10px 25px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
}

.top-left-camera {
  margin-right: -52px;
  margin-bottom: -49px;
  margin-top: 7px;
}
.bettwen-left-camera {
  width: 100%;
}

.margin-left-45 {
  margin-left: -45px;
}

.bottom-left-camera {
  margin-right: -49px;
  margin-top: -22px;
}

.top-right-camera {
  margin-left: -49px;
  margin-top: 7px;
  margin-bottom: -49px;
}

.bottom-right-camera {
  margin-left: -49px;
  margin-top: -22px;
}

hr {
  border: 0;
  border: 1px dashed #c6c6c6;
  box-sizing: border-box;
  transform: matrix(1, 0, 0, -1, 0, 0);
}

.icon-image {
  width: 50px;
}

.background-diff {
  background: #f7fcff;
  padding: 0;
  margin: 0;
}

.margin-top--15 {
  margin-top: -15px !important;
}
.margin-right-24 {
  margin-right: 24px;
}
.margin-left-24 {
  margin-left: 24px;
}
.padding-left-0 {
  padding-left: 0px !important;
}
.padding-left-69 {
  padding-left: 69px !important;
}
.padding-top-10 {
  padding-top: 10px;
}
.padding-top-44 {
  padding-top: 44px;
}
.margin-left--8 {
  margin-left: -8px;
}
.margin-left--25 {
  margin-left: -25px;
}
.margin-left--20 {
  margin-left: -24px;
}
.padding-top-20 {
  padding-top: 20px;
}

.padding-top-25 {
  padding-top: 25px;
}
.padding-top-26 {
  padding-top: 26px;
}

.padding-top-28 {
  padding-top: 28px;
}
.padding-top-30 {
  padding-top: 30px;
}

.padding-top-30 {
  padding-top: 30px;
}

.padding-top-45 {
  padding-top: 45px;
}
.padding-bottom-36 {
  padding-top: 36px;
}
.padding-bottom-64 {
  padding-top: 64px;
}

.title-smart-management {
  top: 80px;
  left: 80px;
}

.padding-bootom-25 {
  padding-bottom: 25px;
}

.padding-right-0 {
  padding-right: 0px !important;
}

.padding-right-40 {
  padding-right: 40px;
}
.padding-left-right-0 {
  padding-left: 0;
  padding-right: 0;
}
.padding-left-right-15 {
  padding-left: 15px;
  padding-right: 15px;
}
.padding-left-35 {
  padding-left: 35px;
}
.padding-left-37 {
  padding-left: 37px !important;
}
.padding-left-69 {
  padding-left: 69px !important;
}
.padding-left-0 {
  padding-left: 0 !important;
}
.padding-right-0 {
  padding-right: 0 !important;
}
.margin-top--15 {
  margin-top: -15px;
}
.z-index-img,
.z-index-title,
.z-index-logo {
  position: absolute;
}

.z-index-title {
  left: 15px;
  top: 190px;
  padding-top: 10px;
}

.z-index-logo {
  left: 300px;
  top: 205px;
}

.btn-details {
  background: #32b6ff;
  border-radius: 6px;
  border: none;
  width: 142px;
  height: 36px;
  text-align: center;
  color: #ffffff;
  font-size: 16px;
  align-items: center;
  gap: 4px;
}

.btn-text {
  font-size: 16px !important;
  color: #ffffff !important;
  font-weight: 500 !important;
  padding-top: 1px !important;
}
.access-control-btn {
  a {
    text-decoration: none !important;
  }
}

.btn-icon {
  padding-top: 2px;
  padding-left: 2px;
}

//sprites icon
.bg-hand_icon {
  width: 41px;
  height: 40px;
  background: url("../../image/css_sprites.webp") -10px -104px;
  display: inline-block;
}
.bg-follow_active_icon {
  width: 42px;
  height: 36px;
  background: url("../../image/css_sprites.webp") -71px -104px;
  display: inline-block;
}
.bg-line {
  width: 100px;
  height: 2px;
  background: url("../../image/css_sprites.webp") -10px -217px;
  display: inline-block;
}
