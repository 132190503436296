.contact-us {
  min-height: 572px;
  background: linear-gradient(101.16deg, #2c50c9 26.62%, #2c86f0 91.91%);
  position: relative;
  padding-top: unset !important;
  padding-bottom: unset !important;
  overflow: unset !important;
  .bg-contact {
    width: 450px;
    height: auto;
    position: absolute;
    left: -35px;
    top: 0;
  }
  .content-contact {
    width: 100%;
    height: auto;
    padding-top: 49px;
    display: flex;
  }
  .infor-contact {
    width: 50%;
  }
  .form-contact {
    width: 50%;
    position: relative;
  }
  .bg-tma_logo {
    width: 180px;
    height: auto;
  }
  .hotline-contact {
    width: 637px;
    height: 80px;
    display: flex;
    margin-top: 41px;
    flex-direction: row;
    align-items: center;
    gap: 21px;
    background: #ffffff;
    box-shadow: 0px 151px 61px rgba(5, 20, 46, 0.01),
      0px 85px 51px rgba(5, 20, 46, 0.05), 0px 38px 38px rgba(5, 20, 46, 0.09),
      0px 9px 21px rgba(5, 20, 46, 0.1), 0px 0px 0px rgba(5, 20, 46, 0.1);
  }
  .phone {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    a{
       text-decoration: none;
       color: #000;
       &:hover{
       color: #2c86f0;
       }
    }
  }
  .phone_detail {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
  .icon-contact {
    height: 32px;
  }
  .title-hotline {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #333333;
    padding-left: 20px;
    margin: unset;
  }
  .icon-email-blue {
    padding-left: 28px;
  }
  .detail-infor {
    padding-top: 36px;
    display: flex;
    gap: 16px;
    flex-direction: column;
    width: 80%;
  }
  hr {
    width: 80%;
    float: left;
  }
  .title-detail-infor {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #ffffff;
  }
  .text-detail {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    color: #ffffff;
    text-decoration: none;
  }
  .icon-contact-detail {
    height: 14px;
    margin-top: 5px;
  }
  .another-contact {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 12px;
  }
  .zoomInPut{
    animation: zoom-in-zoom-out 2s ease-out infinite;
    opacity: 1 ;
  }
  @keyframes zoom-in-zoom-out {
    0% {
        transform: scale(1, 1);
    }
    50% {
        transform: scale(1.3, 1.3);
        opacity: 0.6;
    }
    100% {
        transform: scale(1, 1);
    }
}
  .form-content {
    width: 100%;
    height: auto;
    background: #f2f7ff;
    position: absolute;
    top: -150px;
    clip-path: polygon(88% 0%, 100% 15%, 100% 100%, 0 100%, 0 0);
  }
  .title-form {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #333333;
    padding-top: 108px;
    padding-left: 98px;
    margin: unset;
  }

  .form-input {
    position: relative;
    display: block;
  }
  .submit-button {
    width: 240px;
    height: 48px;
    background: linear-gradient(101.16deg, #2c50c9 26.62%, #2c86f0 91.91%);
    float: right;
    margin-right: 40px;
    margin-bottom: 40px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;

    color: #ffffff;
  }
  .submit-button:hover {
    color: #2d51ca;
    background: #ffffff;
    border: 1px solid #2c86f0 !important;
    transition-duration: 1s;
  }
  .submit-button[disabled="disabled"],
  .submit-button:disabled {
    cursor: not-allowed;
    background: linear-gradient(101.16deg, #2c50c9 26.62%, #2c86f0 91.91%);
    opacity: 0.8;
  }
  .label-form {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    color: rgba(51, 51, 51, 0.5);
    cursor: text;
    display: block;
    left: 0;
    position: absolute;
    top: 32px;
    z-index: 1;
    &::after {
      position: relative;
      content: "*";
      color: red;
      display: inline-block;
    }
  }
  .form-control {
    background: #f2f7ff;
    height: 40px;
    margin-top: 20px;
    width: 100%;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 140%;
    color: rgba(51, 51, 51, 0.5);
  }
  .form-control:focus {
    color: #1a1f28;
    background: #f2f7ff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2remrgba (0, 123, 255, 0.25);
  }
  .form-control:valid + .label-form,
  .form-control:disabled + .label-form {
    display: none;
  }
  .error-form {
    color: red;
    margin: unset;
    font-size: 14px;
    padding-top: 5px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
  }
  .label {
    &::after {
      display: none !important;
    }
  }
  .form-input {
    width: 90%;
    padding-left: 98px;
  }
  .label-form {
    left: 98px;
    top: 10px;
  }
  .error-form {
    width: 90%;
    padding-left: 98px;
  }
  .bg-contact {
    width: 405px;
  }
  .title-form {
    padding-left: 98px;
  }

  //animation button submit
  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    bottom: 15px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #cef;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }

  .bg-tma_logo {
    margin-left: -10px;
  }

  //sprites icon
  .bg-icon_address_white {
    width: 32px;
    height: 17px;
    background: url("../../../image/css_sprites.webp") -190px -116px;
    display: inline-block;
  }

  .bg-icon_email_white {
    width: 16px;
    height: 16px;
    background: url("../../../image/css_sprites.webp") -166px -164px;
    display: inline-block;
  }

  .bg-icon_youtube {
    width: 32px;
    height: 32px;
    background: url("../../../image/css_sprites.webp") -133px -104px;
    display: inline-block;
  }

  .bg-icon_email_blue {
    width: 32px;
    height: 33px;
    background: url("../../../image/css_sprites.webp") -114px -164px;
    display: inline-block;
  }

  .bg-icon_phone_blue {
    width: 32px;
    height: 33px;
    background: url("../../../image/css_sprites.webp") -62px -164px;
    display: inline-block;
    animation: ring 4s .7s ease-in-out infinite;
  }


  .bg-icon_zalo {
    width: 32px;
    height: 32px;
    background: url("../../../image/css_sprites.webp") -10px -164px;
    display: inline-block;
  }

  .bg-icon_phone_white {
    width: 16px;
    height: 16px;
    background: url("../../../image/css_sprites.webp") -202px -164px;
    display: inline-block;
  }

  .bg-icon_facebook {
    width: 32px;
    height: 32px;
    background: url("../../../image/css_sprites.webp") -190px -63px;
    display: inline-block;
  }

  .bg-icon_in {
    width: 32px;
    height: 32px;
    background: url("../../../image/css_sprites.webp") -190px -10px;
    display: inline-block;
  }

  @keyframes ring {
    0% { transform: rotate(0); }
    5% { transform: rotate(30deg); }
    10% { transform: rotate(-28deg); }
    15% { transform: rotate(34deg); }
    20% { transform: rotate(-32deg); }
    25% { transform: rotate(30deg); }
    30% { transform: rotate(-28deg); }
    35% { transform: rotate(26deg); }
    40% { transform: rotate(-24deg); }
    45% { transform: rotate(22deg); }
    50% { transform: rotate(-20deg); }
    55% { transform: rotate(18deg); }
    60% { transform: rotate(-16deg); }
    65% { transform: rotate(14deg); }
    70% { transform: rotate(-12deg); }
    75% { transform: rotate(10deg); }
    80% { transform: rotate(-8deg); }
    85% { transform: rotate(6deg); }
    90% { transform: rotate(-4deg); }
    95% { transform: rotate(2deg); }
    100% { transform: rotate(-1deg); }
  }
}
@media screen and (max-width: 1919px) {
  .contact-us {
    .content-contact {
      gap: 10px;
    }
    .form-contact {
      position: relative;
    }
    .form-content {
      width: 100%;
      height: auto;
      right: 0;
    }
    .hotline-contact {
      width: 100%;
    }

    .icon-email-blue {
      padding-left: unset;
    }
    form {
      display: unset;
    }
    .submit-button {
      float: right;
      position: unset;
      margin-right: 40px;
      margin-bottom: 30px;
    }
    .form-input {
      width: 90%;
      padding-left: 60px;
    }
    .label-form {
      left: 60px;
      top: 10px;
    }
    .error-form {
      width: 90%;
      padding-left: 60px;
    }
    .bg-contact {
      width: 405px;
    }
    .title-form {
      padding-left: 60px;
    }
  }
}
@media screen and (max-width: 1199px) {
  .contact-us {
    min-height: 1155px;
    .content-contact {
      flex-direction: column;
      align-items: center;
      gap: 200px;
    }
    .infor-contact {
      width: 100%;
    }
    .form-contact {
      width: 80%;
      height: auto;
    }
    hr {
      width: 80%;
      float: unset;
    }
    .another-contact {
      gap: 20px;
      justify-content: center;
    }
    .title-form {
      padding-top: 55px;
    }
    .contact-us .form-control {
      margin-top: 15px;
    }
    .form-contact {
      width: 80%;
      height: 500px;
    }
    .detail-infor {
      width: 100%;
      padding-left: 90px;
      padding-right: 90px;
    }
    .hotline-contact {
      width: 80%;
      margin: auto;
    }
    .bg-tma_logo {
      margin-bottom: 40px;
      margin-top: 65px;
      margin-left: -10px;
    }


  }
}
@media screen and (max-width: 991px) {
  .contact-us {
    padding-top: 70px !important;
    .bg-tma_logo {
      margin-top: unset;
    }
  }
}
@media screen and (max-width: 768px) {
  .contact-us {
    padding-top: 40px !important;

    background: linear-gradient(
      101.16deg,
      #2c50c9 26.62%,
      #2c86f0 91.91%
    ) !important;
  }
}
@media screen and (max-width: 767px) {
  .contact-us {
    .form-contact {
      width: 100%;
      height: 490px;
    }
    .detail-infor {
      width: 100%;
      padding-left: unset;
      padding-right: unset;
    }
    .hotline-contact {
      width: 100%;
    }
    .form-content {
      clip-path: polygon(85% 0%, 100% 15%, 100% 100%, 0 100%, 0 0);
    }
  }
}
@media screen and (max-width: 540px) {
  .contact-us {
    .detail-infor {
      width: 100%;
    }
    .hotline-contact {
      width: 100%;
      height: auto;
      flex-direction: column;
      align-items: center;
    }
    .title-hotline {
      padding-top: 30px;
    }
    .phone:last-child {
      padding-bottom: 30px;
    }
    .submit-button {
      width: 160px;
    }
  }
}
@media screen and (max-width: 435px) {
  .contact-us {
    .form-contact {
      width: 100%;
      height: 490px;
    }
    .title-form {
      padding-left: 30px;
    }
    .form-input {
      width: 93%;
      padding-left: 30px;
    }
    .label-form {
      left: 30px;
      font-size: 16px;
    }
    .form-control {
      font-size: 15px;
    }
    .submit-button {
      margin-right: 20px;
    }
  }
}
