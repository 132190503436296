.cotnainer_benefit {
  background-image: url("../../image/Benefit_about/backgroud_benefit.webp");
  background-size: 1366px 395px;
  width: 100%;
  height: 395px;
  background-color: rgba(32, 87, 185, 1);

  .form_fit {
    width: 1140px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .form_benefit {
    display: flex;
    flex-direction: column;
    gap: 60px;
    margin-left: 112px;
    img {
      position: absolute;
      left: -58px;
      top: -10px;
    }
    .benefit {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      position: relative;
    }
    .content_benefit {
      width: 100%;
      height: auto;
      background-image: url("../../image/Benefit_about/boder_box_content.webp");
      background-repeat: no-repeat;
      background-size: 396px 65px;
      min-height: 65px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 160%;
      color: #ffffff;
      padding-left: 45px;
      padding-top: 14px;
    }
  }
  .left {
    width: 100%;
  }
  .right {
    width: 100%;
  }
}
