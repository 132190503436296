.about-us {
  min-height: 1000px;
  padding-top: 130px !important;
  .title-aboutUs {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 49px;
    text-align: center;
    color: #00171f;
  }
  .circle-tma-solution {
    width: 100%;
    height: auto;
  }
  .about-tma-solution {
    display: flex;
    flex-direction: row;
    padding-top: 100px;
  }
  .img-tma-solution {
    width: 40%;
    position: relative;
  }
  .box-tma-solution {
    width: 60%;
    display: flex;
    flex-direction: column;
    position: relative;
    top: 35px;
    left: -20px;
  }
  .box {
    background-size: 100% 138px !important;
    position: relative;
    display: flex;
    flex-direction: row;
    background-repeat: no-repeat !important;
    gap: 32px;
    height: 100%;
    width: 100%;
    align-items: center;
  }

  .text-box {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: #333333;
    padding-bottom: 25px;
    span {
      font-weight: 600;
    }
  }

  .icon-about {
    width: 43.17px;
    height: 40.38px;
    margin-left: 60px;
    margin-bottom: 25px;
  }
  .logo-tma-solution {
    width: 375px;
    height: auto;
    position: absolute;
    left: 16%;
    top: 15%;
    animation: zoom-in-zoom-out 5s ease-out infinite;
    @keyframes zoom-in-zoom-out {
      0% {
        transform: scale(1, 1);
      }
      50% {
        transform: scale(1.1, 1.1);
      }
      100% {
        transform: scale(1, 1);
      }
    }
  }
  .circle,
  .circle-inno {
    width: 250px;
    height: 250px;
    position: absolute;
    left: 26%;
    top: 26%;
  }
  .circle:before,
  .circle:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: -webkit-linear-gradient(left top, #2c50c9 0%, #2c86f0 100%);
    width: 250px;
    height: 250px;
    border-radius: 50%;
  }
  .circle-inno:before,
  .circle-inno:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: -webkit-linear-gradient(left top, #2c50c9 0%, #2c86f0 100%);
    width: 250px;
    height: 250px;
    border-radius: 50%;
  }
  .circle:before,
  .circle-inno::before {
    animation: ripple 2s linear infinite;
  }

  .circle:after,
  .circle-inno::after {
    animation: ripple 2s linear 1s infinite;
  }

  @keyframes ripple {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.3);
      opacity: 1;
    }
    100% {
      transform: scale(1.6);
      opacity: 0;
    }
  }
  .title-tma-solution {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    color: #333333;
    text-align: center;
    padding-right: 25px;
  }
  .about-tma-innovation {
    display: flex;
    flex-direction: row;
    padding-top: 100px;
    padding-bottom: 300px;
    align-items: center;
  }
  .box-tma-innovation {
    width: 40%;
    width: 40%;
    display: flex;
    flex-direction: column;
    gap: 60px;
  }
  .img-tma-innovation {
    width: 60%;
    position: relative;
    left: 95px;
    top: 40px;
  }
  .circle-tma-inno {
    width: 100%;
    height: auto;
  }
  .logo-tma-inno {
    width: 375px;
    height: auto;
    position: absolute;
    left: 47%;
    top: 25%;
    animation: zoom-in-zoom-out 5s ease-out infinite;
    @keyframes zoom-in-zoom-out {
      0% {
        transform: scale(1, 1);
      }
      50% {
        transform: scale(1.1, 1.1);
      }
      100% {
        transform: scale(1, 1);
      }
    }
  }
  .circle-inno {
    left: 54%;
    top: 33%;
  }
  .title-tma-inno {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    color: #333333;
    text-align: center;
    width: 395px;
    position: absolute;
    right: 98px;
    bottom: 120px;
  }
  .box-inno {
    background-size: 100% 100% !important;
    position: relative;
    display: flex;
    flex-direction: column;
    background-repeat: no-repeat !important;
    gap: 10px;
    height: 272.57px;
    width: 932px;
  }
  .title-box-inno {
    padding-left: 75px;
    padding-right: 70px;
    padding-top: 60px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #333333;
  }
  .text-box-inno {
    padding-left: 75px;
    padding-right: 70px;

    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 160%;
    color: #333333;
  }
  ul.text-box-inno {
    padding-left: 80px;
    list-style: none;
    li {
      padding-bottom: 10px;
    }
    li::before {
      content: "";
      position: absolute;
      width: 0.5px;
      height: 1px;
      border: 1px solid;
      color: black;
      left: 66px;
      margin-top: 14px;
    }
  }
  .circle-tma-solution-mobile {
    display: none;
  }
  .circle-tma-innovation-mobile {
    display: none;
  }
}
@media screen and (max-width: 1919px) {
  .about-us {
    .box-tma-solution {
      top: 5px;
      left: -15px;
    }
    .box {
      height: 120px;
      background-size: 100% 115px !important;
    }
    .logo-tma-solution {
      left: 13%;
      top: 12%;
      width: 300px;
    }
    .circle {
      left: 24%;
      top: 23%;
    }
    .circle-inno {
      left: 337px;
      top: 198px;
    }
    .circle:before,
    .circle:after,
    .circle-inno:before,
    .circle-inno:after {
      width: 200px;
      height: 200px;
    }

    .box-inno {
      height: auto;
      width: 700px;
    }
    .text-box-inno {
      padding-right: 20px;
    }
    p.text-box-inno {
      padding-bottom: 40px;
    }
    .box-tma-innovation {
      gap: 20px;
    }
    .circle-tma-inno {
      width: 100%;
      height: auto;
      position: relative;
      top: 11%;
      left: -18px;
    }
    .logo-tma-inno {
      width: 300px;
      left: 43%;
      top: 24%;
    }
    .title-tma-inno {
      font-size: 24px;
      line-height: 32px;
      width: 323px;
      position: absolute;
      right: 70px;
      bottom: 60px;
    }
    .text-box-inno {
      font-size: 16px;
    }
    .title-tma-solution {
      font-size: 24px;
    }
  }
}
@media screen and (max-width: 1199px) {
  .about-us {
    .box {
      height: 100px;
      background-size: 100% 100px !important;
    }

    .logo-tma-solution {
      left: 15%;
      top: 13%;
      width: 240px;
    }
    .circle {
      left: 25%;
      top: 23%;
    }
    .circle:before,
    .circle:after,
    .circle-inno:before,
    .circle-inno:after {
      width: 165px;
      height: 165px;
    }
    .title-tma-solution {
      font-size: 24px;
      line-height: 30px;
    }
    .box-inno {
      height: auto;
      width: 100%;
      clip-path: polygon(100% 9%, 100% 100%, 2% 100%, 2% 3%);
    }
    .circle-tma-inno {
      top: 26%;
      left: -18px;
    }
    .circle-inno {
      left: 50%;
      top: 31%;
    }
    .about-tma-innovation {
      display: flex;
      flex-direction: column-reverse;
      padding-top: 100px;
      padding-bottom: 190px;
    }
    .box-tma-innovation {
      width: 100%;
      position: relative;
    }
    .img-tma-innovation {
      width: 100%;
      left: unset;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .circle-tma-inno {
      display: none;
    }
    .circle-tma-innovation-mobile {
      display: block;
      width: 400px;
      height: auto;
      position: unset;
      top: unset;
      left: unset;
      position: relative;
    }
    .title-tma-inno {
      font-size: 25px;
      line-height: 32px;
      width: 100%;
      bottom: unset;
      right: unset;
      position: relative;
      padding-bottom: 50px;
    }
    .logo-tma-inno {
      width: 250px;
      left: 336px;
      top: 16%;
    }
    .circle-inno {
      left: 378px;
      top: 123px;
    }
    .box-tma-solution {
      top: 0px;
      left: -14px;
    }
  }
}

@media screen and (max-width: 991px) {
  .about-us {
    padding-top: 90px !important;
    .title-aboutUs {
      font-size: 26px;
    }
    .circle-tma-innovation-mobile {
      width: 70%;
    }
    .about-tma-innovation {
      padding-top: unset;
      gap: 30px;
    }
    .about-tma-solution {
      flex-direction: column;
      padding-top: 10px;
      align-items: center;
    }
    .img-tma-solution {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .box-tma-solution {
      width: 100%;
      left: unset;
    }
    .circle-tma-solution {
      display: none;
    }
    .circle-tma-solution-mobile {
      display: block;
      width: 70%;
      height: auto;
    }
    .logo-tma-solution {
      left: 26%;
      top: 13%;
      width: 48%;
      height: auto;
    }
    .circle {
      left: 34%;
      top: 24%;
    }
    .circle:before,
    .circle:after,
    .circle-inno:before,
    .circle-inno:after {
      width: 220px;
      height: 220px;
    }
    .box {
      height: 85px;
      background-size: 100% 100% !important;
    }
    .box-inno {
      background: (
        url("../../../image//solutions/solutionAccessControl/aboutUs/bg-box-mobile.webp")
      ) !important;
      clip-path: unset;
      background-size: 100% 100% !important;
    }
    .title-tma-solution {
      padding-bottom: 40px;
      padding-right: unset;
    }

    .logo-tma-inno {
      left: 178px;
      top: 14%;
      width: 48%;
    }
    .circle-inno {
      left: 233px;
      top: 136px;
    }

    .about-tma-innovation {
      padding-bottom: 80px;
    }
  }
}
@media screen and (max-width: 767px) {
  .about-us {
    .title-aboutUs {
      font-size: 24px;
    }
    .text-box {
      font-size: 18px;
    }
    .box-inno {
      clip-path: unset;
      background-size: auto auto !important;
    }
    .box-inno::before {
      content: "";
      position: absolute;
      top: 0px;
      right: 0px;
      border-top: 50px solid #fff;
      border-left: 50px solid transparent;
      width: 0;
    }
    .box-inno::after {
      content: "";
      position: absolute;
      bottom: 0px;
      left: 0px;
      border-top: 50px solid #fff;
      border-left: 50px solid transparent;
      width: 0;
      transform: rotateZ(180deg);
    }
    .circle:before,
    .circle:after,
    .circle-inno:before,
    .circle-inno:after {
      width: 170px;
      height: 170px;
    }
    .circle {
      left: 33%;
      top: 22%;
    }
    .logo-tma-inno {
      left: 26%;
      top: 14%;
    }
    .circle-inno {
      left: 168px;
      top: 102px;
    }
  }
}
@media screen and (max-width: 600px) {
  .about-us {
    .circle-inno {
      display: none;
    }
    .circle {
      display: none;
    }
    .logo-tma-inno {
      top: 12%;
    }
  }
}
@media screen and (max-width: 500px) {
  .about-us {
    .logo-tma-inno {
      left: 26%;
      top: 11%;
    }
  }
}

@media screen and (max-width: 450px) {
  .about-us {
    .logo-tma-inno {
      top: 10%;
    }
    .about-us .img-tma-innovation {
      height: 350px;
    }
    .box-inno {
      background-size: 110% 110% !important;
    }
  }
}
@media screen and (max-width: 435px) {
  .about-us {
    .box {
      gap: 16px;
    }
    .logo-tma-solution {
    }
    .text-box {
      font-size: 17px;
    }
  }
}
@media screen and (max-width: 388px) {
  .about-us {
    .logo-tma-inno {
      top: 10%;
    }
    .logo-tma-solution {
      top: 12%;
    }
    .circle {
      top: 19%;
    }
  }
}

@media screen and (max-width: 375px) {
  .about-us {
    .box {
      gap: 16px;
    }
    .icon-about {
      margin-left: 35px;
      margin-bottom: 10px;
    }
    .text-box {
      padding-bottom: unset;
      padding-right: 15px;
      line-height: 16px;
      font-size: 16px;
    }

    .title-box-inno {
      padding-left: 40px;
    }
    .text-box-inno {
      padding-left: 40px;
    }
    ul.text-box-inno {
      padding-left: 60px;
    }
    ul.text-box-inno li::before {
      left: 48px;
    }
  }
}

@media screen and (max-width: 320px) {
  .about-us {
    .logo-tma-solution {
      left: 27%;
      top: 11%;
    }
    .logo-tma-inno {
      left: 76px;
      top: 10%;
    }
    .text-box {
      font-size: 14px;
    }
  }
}
