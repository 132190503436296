.solution {
  height: 1200px;
  .title-solution {
    text-align: center;
    font-weight: 700;
    font-size: 40px;
    line-height: 49px;
    color: #333333;
    padding-top: 80px;
    font-family: "Montserrat";
    font-style: normal;
  }
  .title-solution-2 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    text-align: center;
    text-transform: uppercase;
    color: #333333;
    position: absolute;
    left: 38%;
    top: 43%;
  }
  .content-solution {
    height: 100%;
    width: 100%;
    position: relative;
    padding-top: 40px;
  }
  .solution-camera {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .solution-scan {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    top: 348px;
  }
  .solution-vault {
    position: absolute;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    right: 55px;
  }
  .solution-managementSystem {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    bottom: 190px;
    left: 165px;
  }
  .solution-lock {
    position: absolute;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    right: 252px;
    bottom: 216px;
  }
  .content-text {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 140%;
    text-align: center;
    color: #333333;
  }

  .arrow_1 {
    width: 200px;
    height: 164px;
    position: absolute;
    left: 92%;
    top: -55%;
  }
  .arrow_2 {
    width: 118px;
    height: 199px;
    position: absolute;
    left: 70%;
    top: 92%;
  }
  .arrow_3 {
    width: 420px;
    height: 260px;
    position: absolute;
    left: 94%;
    top: 66%;
  }
  .arrow_4 {
    width: 250px;
    height: 52px;
    position: absolute;
    left: 92%;
    top: 61%;
  }
  .arrow_5 {
    width: 80px;
    height: 162px;
    position: absolute;
    left: 60%;
    top: -46%;
  }
  .images {
    width: 235px;
    height: 236px;
  }
  .animation-01 {
    animation: zoom-in 2.5s ease-out infinite;
  }

  @keyframes zoom-in {
    0% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1.3, 1.3);
    }
    100% {
      transform: scale(1, 1);
    }
  }
}
@media screen and (max-width: 1919px) {
  .solution {
    height: 1000px;
    .content-text {
      font-size: 18px;
    }
    .solution-scan {
      top: 287px;
    }
    .images {
      width: 198px;
      height: 197px;
    }
    .title-solution-2 {
      font-size: 25px;
      line-height: 32px;
      left: 40%;
      top: 44%;
    }
    .solution-managementSystem {
      left: 86px;
    }
    .solution-lock {
      right: 115px;
      bottom: 188px;
    }
    .solution-vault {
      right: 0;
    }
    .arrow_1 {
      width: 119px;
      height: 94px;
      left: 84%;
      top: -48%;
    }
    .arrow_2 {
      width: 70px;
      height: 118px;
      left: 65%;
      top: 91%;
    }
    .arrow_3 {
      width: 283px;
      height: 180px;
    }
    .arrow_4 {
      width: 187px;
      height: 42px;
    }
    .arrow_5 {
      width: 64px;
      height: 126px;
      left: 51%;
      top: -43%;
    }
  }
}
@media screen and (max-width: 1199px) {
  .solution {
    height: 950px;
    .title-solution-2 {
      font-size: 20px;
      line-height: 29px;
      left: 40%;
      top: 40%;
    }
    .solution-scan {
      top: 265px;
    }
    .content-text {
      font-size: 16px;
    }
    .images {
      width: 168px;
      height: 169px;
    }
    .solution-managementSystem {
      bottom: 200px;
      left: 50px;
    }
    .solution-lock {
      right: 93px;
      bottom: 215px;
    }
    .solution-vault {
      right: 0;
    }
    .arrow_1 {
      width: 95px;
      height: 75px;
      left: 83%;
      top: -41%;
    }
    .arrow_2 {
      width: 55px;
      height: 110px;
    }
    .arrow_3 {
      width: 223px;
      height: 161px;
    }
    .arrow_4 {
      width: 156px;
      height: 32px;
      left: 95%;
      top: 61%;
    }
    .arrow_5 {
      width: 51px;
      height: 120px;
      left: 51%;
      top: -50%;
    }
  }
}

@media screen and (max-width: 991px) {
  .solution {
    height: 100%;
    .title-solution {
      font-size: 40px;
      padding-top: 50px;
    }
    .solution-scan {
      top: unset;
    }
    .title-solution-2 {
      left: 36%;
      padding-bottom: 20px;
    }
    .content-text {
      font-size: 16px;
      width: 100%;
      // text-align: left;
      order: 1;
    }
    .images {
      width: 130px;
      height: 131px;
    }
    .solution-camera {
      flex-direction: row-reverse;
      justify-content: center;
      display: flex;
      flex-direction: column;
    }

    .solution-vault,
    .solution-lock,
    .solution-managementSystem,
    .solution-scan {
      position: relative;
      flex-direction: row-reverse;
      justify-content: center;
      right: unset;
      left: unset;
      bottom: unset;
      display: flex;
      flex-direction: column;
    }
    .arrow_1,
    .arrow_2,
    .arrow_3,
    .arrow_4,
    .arrow_5 {
      display: none;
    }
    .title-solution-2 {
      position: relative;
      left: unset;
      top: unset;
    }
    // .images {
    //   margin-left: 90px;
    // }
    .content-solution {
      padding-top: 0px;
    }
    .solution-camera {
      margin-top: 20px;
    }
    .solution-scan {
      margin-top: 20px;
    }
    .solution-vault {
      margin-top: 20px;
    }
    .solution-managementSystem {
      margin-top: 20px;
    }
    .solution-lock {
      margin-top: 20px;
    }
  }
}
@media screen and (max-width: 767px) {
  .solution {
    height: auto;
    .title-solution {
      font-size: 24px;
      padding-top: 25px;
    }
    .images {
      margin-left: unset;
    }
  }
}
