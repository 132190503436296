.gallaries {
  position: relative;
  width: 100%;
  height: 100%;
  .slick-slider {
    margin: 0 -15px;
  }
  img {
    width: 96%;
    border-radius: 18px;
  }
.gallaries-img {
  padding: 20px;
}
  .slick-prev,
  .slick-next {
    // width: 50px;
    height: 50px;
  }
  .slick-prev:hover,
  .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    color: #fff;
    outline: none;
  }

  .slick-next::before {
    color: #154499;
    content: ">";
    font-size: 40px;
    font-weight: bold;
  }

  .slick-prev::before {
    color: #154499;
    content: "<";
    font-size: 40px;
    font-weight: bold;
  }


  .slick-prev {
    left: -50px;
    z-index: 4;
  }
}
