.app-tech {
  min-height: 1000px;
  .title-app {
    // padding-top: 100px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 49px;
    text-align: center;
    color: #333333;
    padding-top: 80px;
  }
  .content-app {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .content-top {
    width: 100%;
    height: auto;
    padding-top: 80px;
    gap: 30px;
    justify-content: center;
    margin: auto;
  }

  .box-content {
    height: auto;
    background: #ffffff;
    box-shadow: 2px 16px 30px rgba(2, 24, 89, 0.08);
    max-width: 30% !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .box-content::before {
    content: "";
    position: absolute;
    top: -2px;
    right: -15px;
    border-top: 49px solid #fff;
    border-left: 75px solid transparent;
    width: 0;
  }

  .title-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    gap: 24px;
    width: 100%;
    height: 80px;
    background: linear-gradient(101.16deg, #2c50c9 26.62%, #2c86f0 91.91%);

    p {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 120%;
      display: flex;
      align-items: center;
      color: #ffffff;
    }
  }
  .box-text {
    padding: 32px;
    p {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 160%;
      color: #333333;
    }
  }
  .icon-app {
    width: 48px;
    height: 48px;
    margin-left: 24px;
  }

  .content-bottom {
    width: 100%;
    height: 813px;
    background: linear-gradient(101.16deg, #2c50c9 30.89%, #2c86f0 105.48%);
    margin-top: 179px;
    position: relative;
    overflow: hidden;
  }

  .bg-appTech {
    width: 30%;
    height: auto;
    position: absolute;
    left: 0;
    top: 0;
  }
  .app-target {
    display: flex;
    flex-direction: column;
  }

  .img-target {
    width: 40%;
    position: relative;
  }
  .box-content-target {
    width: 60%;
    top: 150px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
  }

  .title-target {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 49px;
    text-align: center;
    color: #ffffff;
    position: absolute;
    top: 45%;
    left: 25%;
  }

  .content-target {
    display: flex;
    flex-direction: row;
  }
  .circle-app {
    width: 620px;
    height: auto;
    position: relative;
    top: 48px;
    z-index: 5;
    filter: brightness(140%) saturate(70%);
  }
  .circle-app-rolling {
    filter: brightness(200%) saturate(70%);
    width: 400px;
    height: 414px;
    position: absolute;
    top: 128px;
    z-index: 5;
    left: 98px;
    animation: spin 10s linear infinite !important;
    @keyframes spin {
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
  }
  .box {
    width: 100%;
    height: 102px;
    background: #ffffff;
    box-shadow: 2px 16px 26px rgba(2, 24, 89, 0.15);
    position: relative;
    clip-path: polygon(94% 1%, 100% 30%, 100% 100%, 0 100%, 0 0);
    top: 11px;
    display: flex;
    flex-direction: row;
    gap: 32px;
    align-items: center;
  }
  .content-bot {
    width: 1283px;
    margin: auto;
    position: relative;
  }
  .icon-app-target {
    width: 40px;
    height: 39.05px;
    margin-left: 32px;
  }
  .text-box {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 140%;
    color: #333333;
  }
  .text-box-bot {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 140%;
    text-align: center;
    color: #ffffff;
    span {
      font-weight: 600;
    }
  }
  .circle-app-mobile {
    display: none;
  }
}
@media screen and (max-width: 1919px) {
  .app-tech {
    .title-box p {
      font-size: 20px;
    }
    .circle-app {
      width: 620px;
      top: 0px;
      left: -160px;
    }
    .circle-app-rolling {
      top: 80px;
      left: -65px;
    }
    .box {
      height: 88px;
      top: -15px;
    }
    .text-box {
      padding-right: 35px;
    }
    .text-box-bot {
      padding-right: 30px;
      padding-left: 30px;
    }
    .content-bot {
      width: 100%;
      top: -20px;
    }
    .title-target {
      font-size: 35px;
      top: 45%;
      left: 18%;
    }
    .bg-appTech {
      width: 30%;
      height: auto;
    }
  }
}
@media screen and (max-width: 1199px) {
  .app-tech {
    .box {
      height: 75px;
    }
    .circle-app {
      width: 530px;
      top: 22px;
      left: -130px;
    }
    .circle-app-rolling {
      width: 350px;
      height: 364px;
      top: 85px;
      left: -49px;
    }
    .title-box p {
      font-size: 20px;
    }
    .box-content {
      max-width: 45% !important;
    }
    .content-bot {
      top: unset;
    }
  }
}
@media screen and (max-width: 991px) {
  .app-tech {
    padding-top: 40px !important ;
    .title-app {
      font-size: 26px;
      padding-top: 35px;
    }
    .circle-app {
      display: none;
    }
    .circle-app-rolling {
      width: 264px;
      height: 270px;
      top: 57px;
      left: 216px;
    }
    .title-target {
      display: none;
    }
    .circle-app-mobile {
      display: block;
      width: 400px;
      filter: brightness(140%) saturate(70%);
    }
    .content-target {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: unset;
      gap: 28px;
      position: relative;
    }
    .img-target {
      width: 100%;
      height: auto;
      position: relative;
      display: contents;
    }
    .content-bottom {
      height: auto;
    }
    .content-top {
      padding-top: 40px;
    }
    .text-box-bot {
      font-size: 18px;
      padding-top: 40px;
      padding-left: unset;
      padding-right: unset;
    }
    .box-content-target {
      flex-direction: column;
      width: unset;
      top: unset;
    }
    .content-bot {
      width: 100%;
      top: unset;
      padding-top: unset;
      padding-bottom: 40px;
      padding-right: unset;
      padding-left: unset;
    }
    .title-target {
      font-size: 22px;
      position: relative;
      left: 4%;
    }
    .bg-appTech {
      width: 40%;
      height: auto;
    }
    .box {
      min-height: 100px;
      padding-right: 0px;
    }
    .app-target {
      gap: 10px;
    }
    .text-box {
      font-size: 16px;
    }
    .box-content {
      max-width: unset !important;
    }
  }
}
@media screen and (max-width: 767px) {
  .app-tech {
    padding-top: 40px !important ;
    .title-app {
      font-size: 26px;
      padding-top: 35px;
    }
    .circle-app {
      display: none;
    }
    .circle-app-rolling {
      width: 67%;
      height: auto;
      top: 73px;
      left: 90px;
    }
    .title-target {
      display: none;
    }
    .circle-app-mobile {
      display: block;
      width: 100%;
    }
    .content-target {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: unset;
      gap: 28px;
    }
    .img-target {
      width: 100%;
      height: auto;
      position: relative;
    }
    .content-bottom {
      height: auto;
    }

    .text-box-bot {
      font-size: 18px;
      padding-left: unset;
      padding-right: unset;
    }
    .box-content-target {
      flex-direction: column;
      width: unset;
      top: unset;
    }
    .content-bot {
      width: 100%;
      top: unset;
      padding-top: unset;
      padding-bottom: 40px;
      padding-right: unset;
      padding-left: unset;
    }
    .title-target {
      font-size: 22px;
      position: relative;
      left: 4%;
    }
    .bg-appTech {
      width: 40%;
      height: auto;
    }
    .box {
      min-height: 100px;
      padding-right: 0px;
    }
    .app-target {
      gap: 10px;
    }
    .text-box {
      font-size: 16px;
    }
    .box-content {
      max-width: unset !important;
    }
    .content-bottom {
      margin-top: 80px;
    }
    .title-box p {
      font-size: 16px;
    }
    .title-box {
      gap: 10px;
    }
  }
}
@media screen and (max-width: 500px) {
  .app-tech {
    .circle-app-rolling {
      top: 70px;
      left: 80px;
    }
  }
}
@media screen and (max-width: 445px) {
  .app-tech {
    .title-target {
      font-size: 16px;
      top: 40%;
    }
    .app-target {
      gap: 20px;
    }
    .content-bot {
      padding-top: 0;
    }
    .circle-app-rolling {
      top: 58px;
      left: 70px;
    }
  }
}
@media screen and (max-width: 400px) {
  .app-tech {
    .circle-app-rolling {
      top: 7%;
      left: 17%;
    }
  }
}
@media screen and (max-width: 320px) {
  .app-tech {
    .circle-app-rolling {
      top: 42px;
      left: 50px;
    }
  }
}
