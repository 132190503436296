header#home {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center 0px !important;
}
.bg-banner {
  height: 1080px;
}
.banner {
  width: 700px;
  height: auto;
  padding-top: 150px;
  .title-banner-1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 49px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #ffffff;
  }
  .title-banner-2 {
    width: 464px;
    padding-top: 24px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 39px;
    display: flex;
    align-items: center;
    color: #ffffff;
  }
  .button-banner {
    padding-top: 64px;
    display: flex;
    flex-direction: row;
    gap: 40px;
  }
  .button-1 {
    width: 240px;
    height: 48px;
    background: #ffffff;
    border: 2px solid #ffffff;
    color: #2d54cc;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      text-decoration: none;
    }

    cursor: pointer;
  }

  .button-1:hover {
    p {
      color: #ffffff;
    }
    background: #2d54cc;
    border: 2px solid #ffffff;
    transition-duration: 1.5s;
  }
  .button-2 {
    width: 240px;
    height: 48px;
    background: #2d54cc;
    border: 2px solid #ffffff;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    cursor: pointer;
  }
  .button-2:hover {
    color: #2d51ca;
    background: #ffffff;
    border: 2px solid #ffffff;
    transition-duration: 1.5s;
  }
}

@media screen and (max-width: 1199px) {
  .banner {
    // padding-left: 40px;
    padding-top: 120px;
  }
}
@media screen and (max-width: 767px) {
  header#home {
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: -68em 0px !important;
  }
  .banner {
    width: 100%;
    padding-top: 170px;
    .title-banner-1 {
      font-size: 30px;
      width: 100%;
    }
    .title-banner-2 {
      font-size: 25px;
      width: 100%;
    }
  }
}
@media screen and (max-width: 425px) {
  .banner {
    .button-banner {
      flex-direction: column;
    }
  }
}
