#system-support {
  padding-top: unset;
}

.systemSupport_bg {
  background-color: #f2f7ff;
  width: 100%;
  min-height: 1383px;
}
.systemSupport {
  &_title {
    font-weight: 700;
    color: #333333;
    font-size: 40px;
    text-align: center;
    padding-top: 160px;
    padding-bottom: 100px;
  }
  &_items {
    justify-content: center;
    .box_item {
      padding-bottom: 100px;
      .bg_item {
        position: relative;
        background-image: url("../../../image/systemSuport/bg_system_support.webp");
        background-size: 100% auto;
        width: 339px;
        height: 397px;
        background-repeat: no-repeat;
        filter: drop-shadow(0 0 30px rgba(2, 24, 89, 0.08));
        &_icon {
          height: 76px;
          position: absolute;
          top: -20px;
          left: -20px;
        }
        &_bottom {
          padding: 0 40px;
          &_hr {
            border: 1px solid #333333;
            width: 44px;
            float: left;
            background-color: #333333;
          }
          &_title {
            font-weight: 600;
            font-size: 24px;
            padding-top: 48px;
            font-family: "Montserrat";
            font-style: normal;
            padding-right: 25px;
          }
          &_content {
            padding-top: 40px;
            font-weight: 400;
            font-size: 18px;
            color: #333333;
            font-family: "Montserrat";
            font-style: normal;
          }
        }
      }
    }
    .bg_item::before {
      box-shadow: 2px 16px 40px rgba(2, 24, 89, 0.08);
    }
  }
}
.animation-01 {
  cursor: pointer;
}
.animation-01:hover {
  position: relative;
  z-index: 5;
  transform: scale(1.3, 1.3);
  transition-duration: 2s;
}

@media screen and (max-width: 1919px) {
  .systemSupport_bg {
    height: auto;
  }
}
@media screen and (min-width: 1920px) {
  .systemSupport_bg {
    .systemSupport_items {
      .sys-col-box {
        max-width: 25% !important;
      }
    }
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .systemSupport_bg {
    .animation-01:hover {
      transform: scale(1.1, 1.1);
    }
    .systemSupport_items {
      .bg_item {
        width: 100%;
        background-size: 100% 397px;
        &_bottom_title {
          padding-right: 115px;
        }
      }
      .bg_item_bottom_title {
        padding-top: 90px;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .systemSupport_bg {
    .animation-01 {
      animation: none;
    }
    .sys-col-box {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
    .box_item {
      padding-right: 20px;
      padding-left: 20px;
      .bg_item {
        background-size: 100% 410px;
        height: 410px;
        width: 100%;
        &_bottom_title {
          padding-right: unset !important;
        }
      }
    }
    .animation-01:hover {
      transform: scale(1.1, 1.1);
    }
  }
}

@media screen and (max-width: 767px) {
  .systemSupport_bg {
    .systemSupport {
      &_title {
        padding-top: 80px;
        font-size: 24px;
      }
      &_items {
        padding-right: 10px;
        padding-left: 10px;
        .box_item {
          padding-bottom: 50px;
          .bg_item {
            width: 100%;
            height: 360px;
            background-size: 100% 360px;
            &_bottom_title {
              padding-top: 50px;
              width: 100%;
            }
          }
        }
      }
    }
    .animation-01:hover {
      transform: unset;
    }
  }
}
@media screen and (max-width: 410px) {
  .systemSupport_bg {
    .systemSupport {
      &_items {
        .box_item {
          .bg_item {
            height: 470px;
            background-size: 100% 470px;
          }
        }
      }
    }
  }
}
