.icon-hr {
  position: relative;
  bottom: -483px;
  .solution-core-dots-nav {
    padding-top: 11px;
  }
  .solution-core-dots {
    background: linear-gradient(87.95deg, #003087 -6.62%, #279dd8 109.01%);
    width: 10px;
    height: 10px;
    border-radius: 100%;
  }
  .olution-core-hr {
    width: 330px;
    border: 0.5px dashed #279dd8;
  }
  .olution-core-hr-one-nav {
    position: relative;
    top: -24px;
    left: -9px;
  }
  .olution-core-hr-one {
    width: 75px;
    border: 0.5px dashed #279dd8;
    transform: rotate(-40deg);
  }
}

.icon-hr2 {
  position: relative;
  bottom: -98px;
  .solution-core-dots-nav {
    padding-top: 11px;
  }
  .solution-core-dots {
    background: linear-gradient(87.95deg, #003087 -6.62%, #279dd8 109.01%);
    width: 10px;
    height: 10px;
    border-radius: 100%;
  }
  .olution-core-hr {
    width: 330px;
    border: 0.5px dashed #279dd8;
  }
  .olution-core-hr-one-nav {
    position: relative;
    top: 25px;
    left: -9px;
  }
  .olution-core-hr-one {
    width: 75px;
    border: 0.5px dashed #279dd8;
    transform: rotate(40deg);
  }
}
.icon-hr3 {
  position: relative;
  bottom: -289px;
  // padding-left: 91px;
  // right: 15px;
  .solution-core-dots-nav {
    padding-top: 11px;
  }
  .solution-core-dots {
    background: linear-gradient(87.95deg, #003087 -6.62%, #279dd8 109.01%);
    width: 10px;
    height: 10px;
    border-radius: 100%;
  }
  .olution-core-hr {
    width: 388px;
    border: 0.5px dashed #279dd8;
  }
  .olution-core-hr-one-nav {
    position: relative;
    top: 27px;
    left: -7px;
  }
  .olution-core-hr-one {
    width: 75px;
    border: 0.5px dashed #279dd8;
    transform: rotate(45deg);
  }
}

.icon-hr4 {
  position: relative;
  bottom: -103px;
  left: 720px;
  .solution-core-dots-nav {
    padding-top: 11px;
  }
  .solution-core-dots {
    background: linear-gradient(87.95deg, #003087 -6.62%, #279dd8 109.01%);
    width: 10px;
    height: 10px;
    border-radius: 100%;
  }
  .olution-core-hr {
    width: 325px;
    border: 0.5px dashed #279dd8;
  }
  .olution-core-hr-one-nav {
    position: relative;
    top: 26px;
    left: 10px;
  }
  .olution-core-hr-one {
    width: 80px;
    border: 0.5px dashed #279dd8;
    transform: rotate(-40deg);
  }
}
.icon-hr5 {
  position: relative;
  bottom: -310px;
  left: 721px;
  .solution-core-dots-nav {
    padding-top: 11px;
  }
  .solution-core-dots {
    background: linear-gradient(87.95deg, #003087 -6.62%, #279dd8 109.01%);
    width: 10px;
    height: 10px;
    border-radius: 100%;
  }
  .olution-core-hr {
    width: 325px;
    border: 0.5px dashed #279dd8;
  }
  .olution-core-hr-one-nav {
    position: relative;
    top: -26px;
    left: 10px;
  }
  .olution-core-hr-one {
    width: 80px;
    border: 0.5px dashed #279dd8;
    transform: rotate(40deg);
  }
}
.icon-hr6 {
  position: relative;
  bottom: -133px;
  left: 730px;
  .olution-core-hr-one-nav {
    position: relative;
    top: 24px;
    left: 9px;
  }
  .olution-core-hr-one {
    width: 75px;
    border: 0.5px dashed #279dd8;
    // transform: rotate(140deg);
  }
}

.col-box-solution-top-one {
  left: -80px;
}
.col-box-solution-top-two {
  left: -15px;
}
.col-box-solution-top-three {
  left: 47px;
}
.col-box-solution-bottom-one {
  left: -80px;
}
.col-box-solution-bottom-two {
  left: -15px;
}
.col-box-solution-bottom-three {
  left: 47px;
}
// @media (max-width: 2560px) {
//     .icon-hr{
//         padding-left: 689px;
//     }
// }
// @media (max-width: 2555px) {
//     .icon-hr{
//         padding-left: 685px;
//     }
// }
// @media (max-width: 2550px) {
//     .icon-hr{
//         padding-left: 683px;
//     }
// }
// @media (max-width: 2545px) {
//     .icon-hr{
//         padding-left: 682px;
//     }
// }
// @media (max-width: 2540px) {
//     .icon-hr{
//         padding-left: 680px;
//     }
// }
// @media (max-width: 2535px) {
//     .icon-hr{
//         padding-left: 675px;
//     }
// }
// @media (max-width: 2530px) {
//     .icon-hr{
//         padding-left: 670px;
//     }
// }

// @media (max-width: 2518px) {
//     .icon-hr{
//         padding-left: 665px;
//     }
// }
// @media (max-width: 2506px) {
//     .icon-hr{
//         padding-left: 660px;
//     }
// }
// @media (max-width: 2535px) {
//     .icon-hr{
//         padding-left: 665px;
//     }
// }

.icon-t-cam-new {
  width: 175px;
}
