html,
body {
  font-family: "Noto Sans", sans-serif !important;
  position: relative;
  scroll-behavior: unset !important;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
