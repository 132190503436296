.solutions {
  height: 100%;
  width: 100%;
}
@mixin objAbsoluteMiddle() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%); /* IE 9 */
  -webkit-transform: translate(-50%, -50%); /* Safari */
}
.icon_expand {
  transform: rotate(270deg) !important;
  // margin-top: 6px !important;
  font-weight: 100 !important;
  color: #ffffff !important;
}
.icon_form {
  font-size: 25px !important;
}
.image4_retail {
  width: 100%;
  height: 390px;
  object-fit: cover;
}
.middle {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 0;
  padding: 0;
  padding-bottom: 60%;
  img {
    @include objAbsoluteMiddle();
  }
}

.title_solution {
  font-weight: bold;
  font-size: 20px;
  line-height: 140%;
  text-align: justify;
  color: #444444;
  text-decoration: none;
  padding-left: 5px;
}
.smart_ul {
  margin-left: -22px !important;
}
.icon_title {
  font-weight: unset !important;
  margin-left: -15px;
  width: 6px;
  height: 6px;
  color: #32b6ff !important;
  font-size: 13px !important;
  position: absolute;
  padding-top: 2px;
}

.arrow_right {
  color: #32b6ff !important;
  width: 8px;
  height: 8px;
  margin-left: -15px;
  margin-right: 7px;
  position: absolute;
}
.image_top {
  height: 177px;
  width: 100%;
  object-fit: cover;
}
.image_bot {
  height: 167px;
  width: 100%;
  object-fit: cover;
}
.solutions-image {
  position: relative;
  width: 100%;
  padding: 0;
  padding-right: unset !important;
  overflow: hidden;
  .card-image {
    float: left;
    object-fit: cover;
    img {
      object-fit: cover;
      width: 100%;
    }
  }

  .slick-dots {
    bottom: 1vw;
    li {
      button {
        width: 16px;
        height: 16px;
        background: #c4c4c4;
        border-radius: 16px;
        &:before {
          position: absolute;
          border-radius: 16px;
          width: 16px;
          height: 16px;
          content: "";
          text-align: center;
          transition: all 0.3s ease-in-out;
        }
      }
      &.slick-active,
      &:hover {
        button {
          &:before {
            width: 16px;
            height: 16px;
            background: #ffffff;
            opacity: 1;
          }
        }
      }
    }
  }
}
.solutions-text {
  font-family: Roboto;
  font-style: normal;
  background: #f7fcff;
  border-radius: 20px;
  padding-right: 0;
  padding-left: 30px !important;
  p {
    font-weight: 700;
    font-size: 40px;
    line-height: 120%;
    color: #444444;
  }
  div {
    font-size: 16px;
    text-align: justify;
  }
  ul.a {
    padding-left: 1rem;
    padding-top: 20px;
    li {
      list-style: none;
      // list-style-image: url("../../image/icons/list-style-ui.webp");

      span {
        font-weight: bold;
        font-size: 20px;
        line-height: 140%;
        text-align: justify;
        color: #444444;
        text-decoration: none;
      }
      a {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        text-align: justify;
        color: #444444;
      }
    }
  }

  ul.b {
    li {
      list-style: none;
      // list-style-image: url("../../image/icons/list-style-li.webp");
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 180%;
      text-align: justify;
      color: #444444;
    }
  }
}

.padding-top-20 {
  padding-top: 20px;
}
.padding-top-10 {
  padding-top: 10px;
  position: relative;
}
.parking_manager {
  margin-left: -20px;
  p {
    padding-left: 7px !important;
  }
}
.li_father {
  p {
    padding-left: 5px;
    list-style: none;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 180%;
    text-align: justify;
    color: #444444;
  }
}
.padding-top-5 {
  padding-top: 5px;
}
.padding-rigth-17 {
  padding-right: 17px;
}

.padding-top-25 {
  padding-top: 25px;
}

.access-control-title {
  font-weight: 700;
  font-size: 40px;
  line-height: 120%;
}
.access-control-msg {
  padding-top: 20px;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  text-align: justify;
  color: #444444;
}
.access-control-img {
  height: 606px;
}

.smart-building-meg {
  background: #154499;
  border-radius: 6px;
  text-align: center;
  height: 80px;
}

.smart-building-meg > div {
  color: #ffffff;
  font-weight: 500;
  font-size: 15.8px;
  line-height: 150%;
  padding: 14px 12px 11px 13px;
}

.icon-background-bettwen {
  position: absolute;
  top: 4167px;
  right: 0px;
}
.icon-background-bottom {
  position: absolute;
  top: 6235px;
  right: 0px;
}
.icon-background-bettwen-down {
  position: absolute;
  top: 4554px;
}

.icon-background-bottom-Smart-Building {
  position: absolute;
  right: 0;
  top: 6235px;
}
.icon-background-top {
  position: absolute;
}

.text-solution-nav {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 150%;
  text-align: justify;
}

.icon-t-cam-new-Asscess {
  width: 181px;
}
.img-icon {
  width: 100%;
}

@media (min-width: 1440px) {
  // .icon-background-top {
  //   left: 150px;
  // }
  // .icon-background-bettwen {
  //   right: 100px;
  // }
  // .icon-background-bettwen-down {
  //   left: 150px;
  // }
  .icon-background-bottom {
    right: 100px;
  }
  .icon-background-bottom-Smart-Building {
    right: 100px;
  }
}
@media (min-width: 1600px) {
  .icon-background-top {
    left: 150px;
  }
  .icon-background-bettwen {
    right: 100px;
  }
  .icon-background-bettwen-down {
    left: 150px;
  }
  .icon-background-bottom {
    right: 200px;
  }
  .icon-background-bottom-Smart-Building {
    right: 200px;
  }
}
@media (min-width: 1900px) {
  .icon-background-top {
    left: 300px;
  }
  .icon-background-bettwen {
    right: 200px;
    // top: 50px;
  }
  .icon-background-bettwen-down {
    left: 300px;
  }
  .icon-background-bottom {
    right: 300px;
  }
  .icon-background-bottom-Smart-Building {
    right: 300px;
  }
}
@media (min-width: 2200px) {
  .icon-background-top {
    left: 450px;
  }
  .icon-background-bettwen {
    right: 350px;
  }
  .icon-background-bettwen-down {
    left: 450px;
  }
  .icon-background-bottom {
    right: 500px;
  }
  .icon-background-bottom-Smart-Building {
    right: 500px;
  }
}
@media (min-width: 2500px) {
  .icon-background-top {
    left: 600px;
  }
  .icon-background-bettwen {
    right: 500px;
  }
  .icon-background-bettwen-down {
    left: 600px;
  }
  .icon-background-bottom {
    right: 600px;
  }
  .icon-background-bottom-Smart-Building {
    right: 600px;
  }
}
